
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, OnDestroy, signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../modal/boxInfo';
import { IndicadorFilaComponent } from '../indicador-fila/indicador-fila.component';
import { IndicadorOrgaoComponent } from '../indicador-orgao/indicador-orgao.component';
import { MatDividerModule } from '@angular/material/divider';
import { CORES } from '../../modal/mock-cor';
import { breadcrumb } from '../../modal/breadcrumb';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { IndicadorRegionalService } from '../../service/IndicadorRegional.service';
import { IndicadorService } from '../../service/Indicador.service';
import { ICONE } from '../../modal/icone';
import { TamanhoTituloService } from '../../cache/tamanho-indicador';
import { valor } from '../../modal/valor';
import { AuthService } from './../../service/auth.service';
import { UtilSharedDataService } from '../../service/UtilSharedDataService';
import { indicador } from '../../modal/Indicador';
@Component({
  selector: 'app-indicador-posto',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    IndicadorFilaComponent,
    IndicadorOrgaoComponent,
    RouterModule,
    MatDividerModule

  ],
  providers: [ICONE],
  templateUrl: './indicador-posto.component.html',
  styleUrl: './indicador-posto.component.scss'
})

export class IndicadorPostoComponent implements OnInit, OnDestroy {
  public NomeTela = "";
  intervalIds: any[] = [];
  public Icone = "";
  public IdRegFiltro = "";
  public linkRedirect = "";
  public IdTela: number = 0;
  public model: boxInfo | undefined;
  public listaReg: boxInfo[] = [];
  dataReg = [];
  dataPos = [];
  listaPos: boxInfo[] = [];
  listaPosTudo: boxInfo[] = [];
  listaRegTudo: boxInfo[] = [];
  listabreadcrumb: breadcrumb[] = [];
  textInd: string | null = "";
  cores = CORES;
  valorPesquisado: string | undefined = undefined;
  maiorTamanhoTitulo: number = 0;
  ModoApresentacao: boolean = false;
  perfil: any;
  data = [];
  public listaIndEtapa1: indicador[] = [];
  atualizando = false;
  private postosUsuario: number[] | undefined;

  tempoRestante = signal(30); // Tempo inicial
  rodando = signal(true); // Controla se o contador está rodando
  intervalId: any;
  indiceAtual = signal(0);

  constructor(private route: ActivatedRoute,
    private icon: ICONE,
    public router: Router,
    private utilSharedDataService: UtilSharedDataService,
    private tamanhoTituloService: TamanhoTituloService,
    private regiaonalService: IndicadorRegionalService,
    private indicadorService: IndicadorService,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      tipoTela: number,
      textInd: string
    };
    this.IdTela = state?.tipoTela;
    this.textInd = state?.textInd;
    this.listabreadcrumb = [
      new breadcrumb("Dashboard", "/dashboard"),
      new breadcrumb("Posto", ""),
    ];
  }

  setModoApresentacao(statusModoApresentacao: boolean) {
    this.rodando = signal(false);
    this.tempoRestante = signal(30)
    this.utilSharedDataService.updateModoApresentacao(statusModoApresentacao);
  }

  onKeyUp(event: any) {
    if (event == null) {
      this.listaPos = this.listaPosTudo;
      this.listaReg = this.listaRegTudo;
      this.valorPesquisado = undefined;
    }
    else {
      const valorDigitado = event.target.value;
      this.valorPesquisado = valorDigitado;
      var PosFiltrada = this.listaPosTudo.filter(x => x.nome.toLowerCase().trim().includes(valorDigitado.toLowerCase().trim()));
      if (PosFiltrada != undefined) {
        this.listaPos = PosFiltrada;

        this.IdRegFiltro = "";
        var limpandoFiltro = this.listaRegTudo;
        limpandoFiltro.forEach(element => {
          element.selecionadoFiltro = false;
          var NomeNovaCor = element.cor.status.replace("Selec", "");
          var NovaCor = this.cores.find(x => x.status == NomeNovaCor)
          if (NovaCor != undefined)
            element.cor = NovaCor;
        });
        this.listaReg = [];
        PosFiltrada.forEach(element => {
          let item = this.dataPos.find(item => item["posto"]["codigoCentralizado"] === element.id);
          var Reg = limpandoFiltro.find(x => item && item["posto"] && item["posto"]["regiao"] && item["posto"]["regiao"]["id"] == x.id);
          if (Reg != undefined) {
            if (!this.listaReg.includes(Reg))
              this.listaReg.push(Reg)
          }
        });

      }
    }
    this.listaPos.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
    this.listaReg.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);

  }

  filtrarRegiao(newValue: string) {
    this.listaPos = [];
    if (this.IdRegFiltro == newValue) {
      this.IdRegFiltro = "";
      var regFiltrada = this.listaReg.find(x => x.id == newValue);
      if (regFiltrada != undefined) {
        regFiltrada.selecionadoFiltro = false;
        var NomeNovaCor = regFiltrada.cor.status.replace("Selec", "");
        var NovaCor = this.cores.find(x => x.status == NomeNovaCor)
        if (NovaCor != undefined)
          regFiltrada.cor = NovaCor;
      }
      var DataFiltrada = this.dataPos;
      if (this.valorPesquisado != undefined && this.valorPesquisado != "") {
        DataFiltrada = this.dataPos.filter(x => x["posto"] && this.valorPesquisado && ("" + x["posto"]["descricao"]).toLowerCase().trim().includes(this.valorPesquisado.toLowerCase().trim()));
      }
      DataFiltrada.forEach(element => {
        this.listaPos.push(new boxInfo(element['posto']['codigoCentralizado'], element['posto']['descricao'], "", this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"], this.cores.find(x => x.status == element["corFarol"])));
      });
      this.listaPos.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
    }
    else {
      this.IdRegFiltro = newValue;
      var limpandoFiltro = this.listaReg.filter(x => x.selecionadoFiltro);
      limpandoFiltro.forEach(element => {
        element.selecionadoFiltro = false;
        var NomeNovaCor = element.cor.status.replace("Selec", "");
        var NovaCor = this.cores.find(x => x.status == NomeNovaCor)
        if (NovaCor != undefined)
          element.cor = NovaCor;
      });

      var regFiltrada = this.listaReg.find(x => x.id == newValue);
      if (regFiltrada != undefined) {
        regFiltrada.selecionadoFiltro = true;
        var NomeNovaCor = "Selec" + regFiltrada.cor.status;
        var NovaCor = this.cores.find(x => x.status == NomeNovaCor)
        if (NovaCor != undefined)
          regFiltrada.cor = NovaCor;
      }
      var DataFiltrada = this.dataPos;
      if (this.valorPesquisado != undefined && this.valorPesquisado != "") {
        DataFiltrada = this.dataPos.filter(x => x["posto"] && this.valorPesquisado && ("" + x["posto"]["descricao"]).toLowerCase().trim().includes(this.valorPesquisado.toLowerCase().trim()));
      }
      let posByReg = DataFiltrada.filter(item => item["posto"] && item["posto"]["regiao"] && item["posto"]["regiao"]["id"] === newValue);
      posByReg.forEach(element => {
        this.listaPos.push(new boxInfo(element['posto']['codigoCentralizado'], element['posto']['descricao'], "", this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"], this.cores.find(x => x.status == element["corFarol"])));
      });
      this.listaPos.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
    }
  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
    clearInterval(this.intervalId);
  }
  isActive = false;
  isIconsOpen = false;

  toggleMenu() {
    this.isActive = !this.isActive;
    this.isIconsOpen = !this.isIconsOpen;
  }
  ngOnInit() {
    this.indicadorService.getIndicadorAtapa1SemGrafico().pipe(
    ).subscribe((response) => {
      this.data = response["Indicador"];

      let i = 0;
      this.data.forEach(element => {
        i++;
        var Ind = new indicador(
          element['id'],
          element['codigo'],
          element['etapa'],
          element['proximaetapa'],
          element['tipo_resultado'],
          element['nome'],
        );
        Ind.ordemDashboard = element['ordemDashboard'];
        this.listaIndEtapa1.push(Ind);
      });
      this.listaIndEtapa1 = this.listaIndEtapa1.sort((a, b) => {
        if (a.ordemDashboard === undefined) return 1;
        if (b.ordemDashboard === undefined) return -1;
        return a.ordemDashboard - b.ordemDashboard;
      });
    });

    this.perfil = this.authService.role();
    this.maiorTamanhoTitulo = this.tamanhoTituloService.getMaiorTamanhoTitulo();

    this.utilSharedDataService.modoApresentacao$.subscribe(boolean => {
      this.ModoApresentacao = boolean;
      if (boolean) {
        this.textInd ="";
        this.rodando = signal(true);
        this.iniciarContador();
      }
      else
        clearInterval(this.intervalId);
    });

    if (this.maiorTamanhoTitulo == 0)
      this.maiorTamanhoTitulo = 20;

    this.listaReg = [];
    this.listaPos = [];
    let sala: any = localStorage.getItem('salaSituacao');
    this.route.queryParamMap.subscribe(params => {
      let tipotela = params.get('tipoTela');
      this.IdTela = tipotela == null ? 0 : Number.parseInt(tipotela);
      this.indiceAtual.update(signal(this.IdTela));
      this.textInd = params.get('textInd');
      this.setIndicadorCarrega();

    });
  }

  setIndicadorCarrega() {
    let login = localStorage.getItem('login');
    var retrievedObject = localStorage.getItem('salaSituacao');
    if (retrievedObject != null) {
      var sala = JSON.parse(retrievedObject);
      this.postosUsuario = sala['postos'];
      this.regiaonalService.getRegional(this.IdTela, sala, login == undefined ? "" : login, this.postosUsuario).pipe(
      ).subscribe((response) => {
        this.linkRedirect = response["indicador"]["linkRedirect"];
        this.dataReg = response["regioes"];
        this.dataPos = response["postos"];
        let tempoAtualizacao = response["tempoAtualizacao"];
        this.NomeTela = this.dataReg[0]["nome"] + this.textInd
        var ord = this.dataReg[0]["ordenacaoOrientacao"];
        if (ord == "DESC") {
          this.dataReg.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
          this.dataPos.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
        } else if (ord == "CRES") {
          this.dataReg.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
          this.dataPos.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
        }
        this.dataReg.forEach(element => {
          var ind = new boxInfo(element['regiao']['id'], element['regiao']['descricao'], "", this.cores.find(x => x.status == element["cor"]), "" + element['valor']['valorFormatado'], undefined, element["icone"]);
          ind.valorNum = element['valor']['valorSemFormato'];
          this.listaReg.push(ind);
        });

        this.dataPos.forEach(element => {
          var ind = new boxInfo(element['posto']['codigoCentralizado'], element['posto']['descricao'], "", this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"], this.cores.find(x => x.status == element["corFarol"]));
          ind.valorNum = element['valor']['valorSemFormato'];
          this.listaPos.push(ind);
        });

        this.listaPos.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        this.listaPos.sort((a, b) => {
          if (a.cor.ordenacao !== b.cor.ordenacao) {
            return a.cor.ordenacao - b.cor.ordenacao;
          } else {
            const ordenacaoA = a.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            const ordenacaoB = b.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            return ordenacaoA - ordenacaoB;
          }
        });
        this.listaReg.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        this.listaPosTudo = this.listaPos;
        this.listaRegTudo = this.listaReg;

        this.setAtualizacaoindicadores(tempoAtualizacao, this.IdTela, sala, login == undefined ? "" : login);
      });
    }
  }

  setAtualizacaoindicadores(timeInterval: number, codigo: number, sala: any, login: string) {

    const intervalId = setInterval(() => {
      console.log("setAtualizacaoindicadores!!");
      this.atualizando = true;

      this.regiaonalService.getRegional(this.IdTela, sala, login == undefined ? "" : login, this.postosUsuario).pipe(
      ).subscribe((response) => {
        this.linkRedirect = response["indicador"]["linkRedirect"];
        this.dataReg = response["regioes"];
        this.dataPos = response["postos"];
        let tempoAtualizacao = response["tempoAtualizacao"];
        this.NomeTela = this.dataReg[0]["nome"] + this.textInd;
        var ord = this.dataReg[0]["ordenacaoOrientacao"];
        if (ord == "DESC") {
          this.dataReg.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
          this.dataPos.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
        } else if (ord == "CRES") {
          this.dataReg.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
          this.dataPos.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
        }

        this.dataPos.forEach(element => {
          var newind = new boxInfo(element['posto']['codigoCentralizado'], element['posto']['descricao'], ""
            , this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"]
            , this.cores.find(x => x.status == element["corFarol"]));
          newind.valorNum = element['valor']['valorSemFormato'];
          var oldind = this.listaPos.find(x => x.id == element['posto']['codigoCentralizado']);
          var oldindtudo = this.listaPosTudo.find(x => x.id == element['posto']['codigoCentralizado']);
          if (oldind != undefined) {
            oldind.valor = newind.valor;
            oldind.cor = newind.cor;
            oldind.corfarol = newind.corfarol;
            oldind.nome = newind.nome;
            oldind.icone = newind.icone;
            oldind.valorNum = newind.valorNum;
          }
          else {
            if (this.valorPesquisado || this.IdRegFiltro != "") {
              if (this.valorPesquisado) {
                if (element["posto"] && ("" + element["posto"]["descricao"]).toLowerCase().trim().includes(this.valorPesquisado.toLowerCase().trim())) {

                  if (this.IdRegFiltro != "") {
                    if (element["posto"] && element["posto"]["regiao"] && element["posto"]["regiao"]["id"] === this.IdRegFiltro) {
                      this.listaPos.push(newind);
                    }
                  } else {
                    this.listaPos.push(newind);
                  }
                }
              }
              else if (element["posto"] && element["posto"]["regiao"] && element["posto"]["regiao"]["id"] === this.IdRegFiltro) {
                this.listaPos.push(newind);
              }
            }
            else {
              this.listaPos.push(newind);
            }

          }
          if (oldindtudo != undefined) {
            oldindtudo.valor = newind.valor;
            oldindtudo.cor = newind.cor;
            oldindtudo.corfarol = newind.corfarol;
            oldindtudo.nome = newind.nome;
            oldindtudo.icone = newind.icone;
            oldindtudo.valorNum = newind.valorNum;
          }
          else {
            this.listaPosTudo.push(newind);
          }
        });
        this.dataReg.forEach(element => {
          var newind = new boxInfo(element['regiao']['id'], element['regiao']['descricao'], "", this.cores.find(x => x.status == element["cor"]),
            "" + element['valor']['valorFormatado'], undefined, element["icone"], this.cores.find(x => x.status == element["cor"]));
          newind.valorNum = element['valor']['valorSemFormato'];
          var oldind = this.listaReg.find(x => x.id == element['regiao']['id']);
          var oldindtudo = this.listaRegTudo.find(x => x.id == element['regiao']['id']);
          if (oldind != undefined) {
            oldind.valor = newind.valor;
            if (this.IdRegFiltro == "") {
              oldind.cor = newind.cor;
            }
            else if (element['regiao']['id'] == this.IdRegFiltro) {
              var NomeNovaCor = "Selec" + newind.cor.status;
              var NovaCor = this.cores.find(x => x.status == NomeNovaCor);
              if (NovaCor)
                oldind.cor = NovaCor;
            }

            oldind.nome = newind.nome;
            oldind.icone = newind.icone;
            oldind.valorNum = newind.valorNum;
          }
          else {
            if (this.valorPesquisado == undefined || this.valorPesquisado == "") {
              if (this.IdRegFiltro == "") {
                this.listaReg.push(newind);
              }
              else if (element['regiao']['id'] == this.IdRegFiltro) {
                this.listaReg.push(newind);
              }
            }
          }
          if (oldindtudo != undefined) {
            oldindtudo.valor = newind.valor;
            if (this.IdRegFiltro == "") {
              oldindtudo.cor = newind.cor;
            }
            else if (element['regiao']['id'] == this.IdRegFiltro) {
              var nomeNovaCor = "Selec" + newind.cor.status;
              var novaCor = this.cores.find(x => x.status == nomeNovaCor);
              if (novaCor)
                oldindtudo.cor = novaCor;
            }
            oldindtudo.nome = newind.nome;
            oldindtudo.icone = newind.icone;
            oldindtudo.valorNum = newind.valorNum;
          }
          else {
            if (this.valorPesquisado == undefined || this.valorPesquisado == "") {
              if (this.IdRegFiltro == "") {
                this.listaRegTudo.push(newind);
              }
              else if (element['regiao']['id'] == this.IdRegFiltro) {
                this.listaRegTudo.push(newind);
              }
            }

          }
        });
        //this.listaPos.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        if (ord == "DESC") {
          this.listaPos.sort((a, b) => b.valorNum - a.valorNum);
          this.listaReg.sort((a, b) => b.valorNum - a.valorNum);
        } else if (ord == "CRES") {
          this.listaReg.sort((a, b) => a.valorNum - b.valorNum);
          this.listaPos.sort((a, b) => a.valorNum - b.valorNum);
        }
        this.listaPos.sort((a, b) => {
          if (a.cor.ordenacao !== b.cor.ordenacao) {
            return a.cor.ordenacao - b.cor.ordenacao;
          } else {
            const ordenacaoA = a.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            const ordenacaoB = b.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            return ordenacaoA - ordenacaoB;
          }
        });
        this.listaReg.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        //this.listaPosTudo.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        this.listaPosTudo.sort((a, b) => {
          if (a.cor.ordenacao !== b.cor.ordenacao) {
            return a.cor.ordenacao - b.cor.ordenacao;
          } else {
            const ordenacaoA = a.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            const ordenacaoB = b.corfarol?.ordenacao ?? Number.MAX_SAFE_INTEGER;
            return ordenacaoA - ordenacaoB;
          }
        });
        this.listaRegTudo.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
        this.atualizando = false;
      });
    }, (timeInterval * 1000));

    this.intervalIds.push(intervalId);
  }

  onSelectChangeIrParaIndicador(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    

    const novoIndice = this.listaIndEtapa1.findIndex(
      (indicador) => indicador.codigo === Number.parseInt(selectedValue)
    );
    if (novoIndice !== -1) {
      this.intervalIds.forEach(intervalId => clearInterval(intervalId));
      this.tempoRestante.set(30);
      this.rodando = signal(true);
      this.indiceAtual = signal(novoIndice); // Atualiza o índice para o selecionado
      var cod = this.listaIndEtapa1[this.indiceAtual()].codigo;
      if(cod != undefined){
        this.IdTela = cod;
      }
      this.listaPos = [];
      this.listaReg = [];
      this.setIndicadorCarrega();
      
    }
  }

  navigateWithState(Link: string | null, CcPosto: string, valor: string) {
    this.setModoApresentacao(false);
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    var textPos = this.extractMatches(valor);
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcPosto: CcPosto, textInd: this.textInd, textPos: textPos }
    });

  }

  navigateWithStateBC(Link: string | null) {
    this.router.navigate(['' + Link], {
      queryParams: {}
    });
  }

  extractMatches(valor: string): string {
    const regex = /\s\d+\/\d+/g;
    const matches = valor.match(regex);
    if (matches) {
      return " " + matches.join(' ').trim();
    }
    return "";
  }

  iniciarContador() {
    this.intervalId = setInterval(() => {
      if (this.rodando() && this.tempoRestante() > 0) {
        this.tempoRestante.update((t) => t - 1);
      } else if (this.tempoRestante() === 0) {
        this.intervalIds.forEach(intervalId => clearInterval(intervalId));
        this.indiceAtual.update((i) => (i + 1) % this.listaIndEtapa1.length);
        var cod = this.listaIndEtapa1[this.indiceAtual()].codigo;
        if(cod != undefined){
          this.IdTela = cod;
        }
        this.listaPos = [];
        this.listaReg = [];
        this.setIndicadorCarrega();
        this.tempoRestante.set(30); // Reinicia o contador

      }
    }, 1000);
  }

  toggleContador() {
    this.rodando.update((r) => !r);
  }

}

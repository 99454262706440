import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PASCadastradosService } from '../../../../../service/pascadastrados.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    RouterLink,
    RouterOutlet,
    RouterModule,
    ActivatedRoute,
    Router,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PAsContratados } from '../../../../../modal/PAsCadastrados';
import { ConfirmDialogComponent } from '../../../dialog/confirmdialog.component';
import { AuthService } from '../../../../../service/auth.service';
//import { error } from 'console';

@Component({
    selector: 'app-pas-listagem',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,
        CommonModule,
        RouterLink,
        RouterOutlet,
        RouterModule,
        FormsModule,
        MatPaginatorModule,
    ],
    templateUrl: './pas-listagem.component.html',
    styleUrl: './pas-listagem.component.scss',
})
export class PasListagemComponent {

    public data = [];
    public body: PAsContratados[] = [];
    public filtroPorNome: PAsContratados = new PAsContratados();

    pageEvent: PageEvent | undefined;
    disabled = false;
    showPageSizeOptions = true;
    showFirstLastButtons = true;
    hidePageSize = false;
    pageSizeOptions = [5, 10, 25];

    //Pesquisa
    pesquisaNomePonto: string = '';
    pesquisaDesc: string = '';
    pageIndex = 0;
    totalPages = 1;
    pageSize = 10;
    usuario: any;
    constructor(
        private pASContratService: PASCadastradosService,
        public dialog: MatDialog, private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        try {
            let login = localStorage.getItem('login');
            if (login == null || login == undefined)
                this.router.navigate(['/iniciar']);
        } catch (ex) {
            this.router.navigate(['/iniciar']);
        }
    }

    ngOnInit() {

        // trás todos pontos ordenado por nome

        this.usuario = localStorage.getItem('salaSituacao');

        this.Pesquisa();
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput
                .split(',')
                .map((str) => +str);
        }
    }
    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.totalPages = e.length;
        this.pageSize = e.pageSize;
        this.pageIndex = e.pageIndex;
        this.Pesquisa();
    }
    DeletePASContratadoItemPorID(id: string | null): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirmação',
                message: 'Tem certeza que deseja excluir?',
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(id);
                if (id != undefined) {
                    this.pASContratService
                        .DeletePASContratadoItemPorID(id?.toString())
                        .subscribe(
                            (response) => {
                                console.log('Item excluído com sucesso!');
                                this.Pesquisa();
                            },
                            (error) => {
                                console.error('Erro ao excluir o item:', error);
                            }
                        );
                }
            }
        });
    }

    onOptionChangeFiltro(newValue: string) {
        this.pesquisaNomePonto = newValue;
    }

    MudaFiltro() {
        this.pageIndex = 0;
        this.Pesquisa();
    }
    Pesquisa() {
        this.pASContratService.GetPASContratados(this.usuario).pipe(
        ).subscribe((response) => {
            console.log(response);
            this.data = response["pontos"];
            let i = 0;
            this.data.forEach(element => {
                i++;
                this.body.push(new PAsContratados(
                    element["id"],
                    element["idPosto"],
                    element["nomePosto"],
                    element["quantidadePontos"],
                    element["dataCriacao"]));
            });
            if (this.pesquisaNomePonto.trim() != "" && this.pesquisaNomePonto.trim() != undefined) {
                var p = this.body.filter(x => x.nomePosto.trim().toLocaleLowerCase().includes(this.pesquisaNomePonto.trim().toLocaleLowerCase()));
                if (p != undefined)
                    this.body = p;
                else
                    this.body = [];
            }
            this.body = this.body.sort((a, b) => {
                if (a.nomePosto < b.nomePosto) return -1;
                if (a.nomePosto > b.nomePosto) return 1;
                return 0;
            });
        });
        this.body = [];
    }

    isAsc: boolean = true;

    sortTable(column: keyof PAsContratados) {
        this.body.sort((a, b) => {
            let itemA = a[column];
            let itemB = b[column];

            // Verifique se itemA e itemB são valores válidos
            if (itemA === null || itemA === undefined) itemA = '';
            if (itemB === null || itemB === undefined) itemB = '';

            if (typeof itemA === 'string' && typeof itemB === 'string') {
                itemA = itemA.toUpperCase();
                itemB = itemB.toUpperCase();
            }

            if (this.isAsc) {
                if (itemA < itemB) return -1;
                if (itemA > itemB) return 1;
                return 0;
            } else {
                if (itemA > itemB) return -1;
                if (itemA < itemB) return 1;
                return 0;
            }
        });
        this.isAsc = !this.isAsc;
    }

    validarRoleSuper(){
        let role = this.authService.role();
        if(role == 'superuser' || role == 'superintendencia'){
            return true;
        }
        return false;
      }
}

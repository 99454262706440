import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { tabela } from '../modal/tabela';

@Injectable({
  providedIn: 'root',
})
export class TabelaService {
  constructor(private http: HttpClient) { }
  private apiUrl = environment.API_URL;
  private map = "ss.tabela";
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  get(): Observable<any> {
    return this.http.get(this.apiUrl + this.map + '/find', { withCredentials: true });
  }

  adicionarItem(item: tabela): Observable<tabela> {
    return this.http.post<tabela>(this.apiUrl + this.map + '/cadastro', item, { withCredentials: true });
  }

  deletarItem(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar/${id}`);
  }
}

import { Injectable } from '@angular/core';
import DiffMatchPatch from 'diff-match-patch';


@Injectable({
  providedIn: 'root'
})
export class TextDiffService {
  private dmp = new DiffMatchPatch();

  getDiff(text1: string, text2: string): string {
    const diffs = this.dmp.diff_main(text1, text2);
    this.dmp.diff_cleanupSemantic(diffs);

    return diffs.map(([op, text]) => {
      switch (op) {
        case -1: return `<span class="removed">${text}</span>`; // Texto removido
        case 1: return `<span class="added">${text}</span>`; // Texto adicionado
        default: return text; // Texto igual
      }
    }).join('');
  }
}
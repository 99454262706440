<section>
  <section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                Visualização de Auditoria do Cadastro
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>
  <main>
    <div *ngIf="MensagemError != ''" class="alert alert-danger" role="alert">
      {{MensagemError}}
    </div>
    <section class="main-section" style="    padding-left: 20px;">
      <form class="form-class" data-toggle="collapse">
        <input type="text" class="form-control" id="id" name="id" [(ngModel)]="novoItem.id" required
          style="display: none;">
        <div style="display: flex;">
          <div class="form-div p-3" style="width: 50%;">
            <label for="identificadorNumerico" class="form-label">Identificador Numerico</label>
            <input type="text" class="form-control" id="identificadorNumerico" name="identificadorNumerico" disabled
              [(ngModel)]="novoItem.identificadorNumerico" required>
          </div>

          <div class="form-div p-3" style="width: 50%;">
            <label for="identificadorDescritivo" class="form-label">Identificador Descritivo</label>
            <input type="text" class="form-control" id="identificadorDescritivo" name="identificadorDescritivo" disabled
              [(ngModel)]="novoItem.identificadorDescritivo" required>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
        </div>
        <div style="display: flex;">
          <div class="form-div p-3"  style="width: 50%;">
            <label for="tabela" class="form-label">Tabela</label>
            <input type="text" class="form-control" id="tabela" name="tabela" [(ngModel)]="novoItem.tabela" required disabled>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
          <div class="form-div p-3"  style="width: 50%;">
            <label for="acao" class="form-label">Ação</label>
            <input type="text" class="form-control" id="acao" name="acao" [(ngModel)]="novoItem.acao" required disabled>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
        </div>
        <div style="display: flex;">
          <div class="form-div p-3"  style="width: 50%;">
            <label for="usuario" class="form-label">Usuario</label>
            <input type="text" class="form-control" id="usuario" name="usuario" [(ngModel)]="novoItem.usuario" required disabled>
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
          <div class="form-div p-3"  style="width: 50%;">
            <label for="dataCadastro"  class="form-label">Data de Atualização</label>
            <input type="text" class="form-control" id="dataCadastro" name="dataCadastro" [(ngModel)]="dataCadastro" required disabled> 
            <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
          </div>
        </div>
        <div>
          <div style="display: flex;    justify-content: space-between;align-items: flex-end;">
            

          <h3>Registro:</h3>
          <div style="    height: 40px !important;">
          <button id="class-button"  *ngIf="novoItem.acao == 'Editar'" (click)="alterarModoViewTextoDiferenca()" class="btn btn-secondary">{{buttonTxtViewRegistro}}</button>
          </div>
        </div>
          <div  *ngIf="modoDiferencaAlto" [innerHTML]="TextoDiferenca" class="diff-area"></div>
          <div *ngIf="!modoDiferencaAlto">
          <h5>Registro Original:</h5>
          <div [innerHTML]="novoItem.jsonOriginal" class="diff-area"></div>
          <h5>Registro Alterado:</h5>
          <div [innerHTML]="novoItem.jsonAlterado" class="diff-area"></div>
        </div>
        </div>
        <div class="div-button mb-3 p-3">
          <a
              style="text-decoration: auto !important; color:white !important;"
              [routerLink]="['/auditoria-listagem']" [queryParams]="{id: id, tabela: tabela }"><button id="class-button" class="btn btn-secondary">Voltar</button></a>
        </div>
      </form>
    </section>
  </main>
</section>
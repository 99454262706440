<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Listagem de Posto
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <!-- <div class="cd-breadcrumb custom-separator"  role="alert">
            <i class="fa-solid fa-circle-info"></i> Obs: Temos um tempo de esperar de 1 minuto para confirmação de cadastrou/edição de posto!
        </div> -->
        @if(postoEditado == true){
        <div class="alert alert-success" role="alert">
            Posto editado com sucesso !
        </div>
        }@if(postoEditado == false){
        <div class="alert alert-danger" role="alert">
            Ocorreu algum problema na edicação !
        </div>
        }
        @if(postoSalvo == true){
        <div class="alert alert-success" role="alert">
            Posto cadastrado com sucesso !
        </div>
        }@if(postoSalvo == false){
        <div class="alert alert-danger" role="alert">
            Ocorreu algum problema no cadastro !
        </div>
        }
        @if(postoExcluido == true){
        <div class="alert alert-success" role="alert">
            Posto excluído com sucesso !
        </div>
        }@if(postoExcluido == false){
        <div class="alert alert-danger" role="alert">
            Ocorreu algum problema ao excluído !
        </div>
        }
        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaCodigo" class="form-label">Código do Posto:</label>
                                    <input type="text" class="form-control" placeholder="Codigo" id="pesquisaCodigo"
                                        name="pesquisaCodigo" [(ngModel)]="pesquisaCodigo" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaCCentralizado" class="form-label">Código Centralizado:</label>
                                    <input type="text" class="form-control" placeholder="Codigo Centralizado"
                                        id="pesquisaCCentralizado" name="pesquisaCCentralizado"
                                        [(ngModel)]="pesquisaCCentralizado" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDesc" class="form-label">Nome do Posto:</label>
                                    <input type="text" class="form-control" placeholder="Nome do Posto"
                                        id="pesquisaDesc" name="pesquisaDesc" [(ngModel)]="pesquisaDesc" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="regiao" class="form-label">Região:</label>
                                    <select #mySelectFiltro name="select" aria-placeholder="Regiao" class="form-select"
                                        (change)="onOptionChangeFiltro(mySelectFiltro.value)">
                                        <option value=""></option>
                                        <option *ngFor="let objeto of listaReg" value={{objeto.id}}>{{objeto.descricao}}
                                        </option>
                                        <option value="-1">Sem Região</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="regiao" class="form-label">Status:</label>
                                    <select #mySelectFiltroStatus name="select" aria-placeholder="Regiao"
                                        class="form-select"
                                        (change)="onOptionChangeFiltroStatus(mySelectFiltroStatus.value)">
                                        <option value="0"></option>
                                        <option value="1">Ativos</option>
                                        <option value="2">Inativos</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                                </div>
                            </td>
                            @if(postoEditado == true || postoSalvo == true){
                            <td style="position: fixed; bottom: 13px; right: 22px;">
                                <button id="class-button" style="border-radius: 50%;width: 45px;height: 45px;"
                                    class="btn btn-success" disabled><i class="fas fa-plus"></i></button>
                            </td>
                            }@else {
                            @if(validarRole() == true ){
                            <td style="position: fixed; bottom: 13px; right: 22px;">
                                <a [routerLink]="['/posto-cadastro']"><button id="class-button"
                                        style="border-radius: 50%;width: 45px;height: 45px;" class="btn btn-success"><i
                                            class="fas fa-plus"></i></button></a>
                            </td>
                            }

                            }
                        </tr>
                    </tbody>
                </table>
            </form>

        </section>
        <!--Tabela -->
        <section class="main-section-table">
            <!-- <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator> -->
            <table class="table">
                <!--toJsonBody-->
                <thead>

                    <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" (click)="sortTable('codigo.keyword')" style="cursor: pointer">Codigo do Posto
                        </th>
                        <th scope="col" (click)="sortTable('codigoCentralizado.keyword')" style="cursor: pointer">Codigo
                            Centralizado</th>
                        <th scope="col" (click)="sortTable('descricao.keyword')" style="cursor: pointer">Nome do Posto
                        </th>
                        <th scope="col" (click)="sortTable('regiao.descricao.keyword')" style="cursor: pointer">Região
                        </th>
                        <th scope="col" (click)="sortTable('inativo')" style="cursor: pointer">Status</th>
                        <!--<th scope="col">Serviços do posto</th>
            <th scope="col">Órgãos do posto</th>-->
                        @if(validarRoleEditar() == true){
                        <th scope="col">Funções</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (p of listaPos; track $index) {
                    <tr>
                        <!-- <td style="font-weight: bold;">{{$index+1}}</td> -->
                        <td style="font-weight: bold;">{{p.codigo}}</td>
                        <td>{{p.codigoCentralizado}}</td>
                        <td>{{p.descricao}}</td>
                        <td>{{p.regiao?.codigo}} - {{p.regiao?.descricao}}</td>
                        @if(p.inativo == undefined || p.inativo == false){
                        <td>Ativo</td>
                        }
                        @if(p.inativo == true)
                        {
                        <td>Inativo</td>
                        }

                        <!--<td></td>
            <td></td>-->
                        @if(validarRoleEditar() == true)
                        {
                        <td>
                            @if(postoEditado == true || postoSalvo == true){
                            <button class="btn btn-primary" style="margin-right: 10px;" disabled>Editar</button>

                            @if(validarRole() == true){
                            <button class="btn btn-danger" (click)="excluirItem(p.id)" disabled>Excluir</button>
                            }
                            @if(validarRole() == true){
                            <a [routerLink]="['/auditoria-listagem']"
                                [queryParams]="{id: p.codigoCentralizado, tabela: 'Posto'}"><button
                                    class="btn btn-secondary" style="margin-left: 10px;" disabled><i
                                        class="fas fa-history"></i></button></a>
                            }
                            }@else{
                            <a class="btn btn-primary" style="margin-right: 10px;" [routerLink]="['/posto-cadastro']"
                                [queryParams]="{IdEditar: p.id}">Editar</a>

                            @if(validarRole() == true){
                            <button class="btn btn-danger" (click)="excluirItem(p.id)">Excluir</button>
                            }
                            @if(validarRole() == true){
                            <a [routerLink]="['/auditoria-listagem']"
                                [queryParams]="{id: p.codigoCentralizado, tabela: 'Posto'}"><button
                                    class="btn btn-secondary" style="margin-left: 10px;"><i
                                        class="fas fa-history"></i></button></a>
                            }
                            }
                        </td>
                        }


                    </tr>
                    }
                </tbody>
            </table>

            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>

        </section>


    </main>
</section>
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject, ViewEncapsulation } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { auditoriaCadastro } from '../../../../modal/auditoriaCadastro';
import { TextDiffService } from '../../../../utilidades/TextDiffService';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuditoriaCadastroService } from '../../../../service/auditoriaCadastro.service';
import { DateTimeFormatPipe } from '../../../../utilidades/date-time-format.pipe';

@Component({
  selector: 'app-auditoria-cadastro-visualizar',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule,
    NgxMaskDirective
  ],
  templateUrl: './auditoria-cadastro-visualizar.component.html',
  styleUrl: './auditoria-cadastro-visualizar.component.scss',
  providers: [provideNgxMask({ /* opções de cfg */ }), DateTimeFormatPipe],
  encapsulation: ViewEncapsulation.None
})

export class AuditoriaCadastroVisualizarComponent implements OnInit {
  data = [];
  novoItem: auditoriaCadastro = new auditoriaCadastro();
  public MensagemError = "";
  limiteHorario: boolean = true;
  lengthValorDigitado: any;
  touched: boolean = false;
  verificarCodigo: boolean = false;
  verificarCodigoCentralziado: boolean = false;
  url: any;
  TextoDiferenca: SafeHtml = '';
  buttonTxtViewRegistro = "Fazer comparação manual";
  dataCadastro ="";
  modoDiferencaAlto = true;
  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;
  id: string | undefined = undefined;
  tabela: string | undefined = undefined;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,public dateTimeFormatPipe: DateTimeFormatPipe,
    private auditoriaCadastroService: AuditoriaCadastroService,
    public dialog: MatDialog, private textDiffService: TextDiffService,
    private sanitizer: DomSanitizer) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  alterarModoViewTextoDiferenca() {
    this.modoDiferencaAlto = !this.modoDiferencaAlto;
    if(!this.modoDiferencaAlto)
      this.buttonTxtViewRegistro = "Fazer comparação automatica";
    else
      this.buttonTxtViewRegistro = "Fazer comparação manual";
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.tabela = params['tabela'];
    });
    this.url = this.router.url;
    this.route.queryParams.subscribe(params => {
      let idEditar = params['IdEditar'];
      if (idEditar != undefined)
        this.AbrirEditarItem(idEditar);
    });

  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.auditoriaCadastroService.editarItem(id?.toString()).subscribe(
        (response) => {
          console.log(response);
          let p = response["AUDITORIA"];

          this.novoItem.id = p['id'];
          this.novoItem.identificadorNumerico = p['identificadorNumerico'];
          this.novoItem.identificadorDescritivo = p['identificadorDescritivo'];
          this.novoItem.tabela = p['tabela'];
          this.novoItem.acao = p['acao'];
          this.novoItem.dataAtualizacao = p['dataAtualizacao'];
          this.novoItem.usuario = p['usuario'];
          this.dataCadastro= this.dateTimeFormatPipe.transform(this.novoItem.dataAtualizacao);
          this.novoItem.jsonOriginal = p['jsonOriginal'];
          this.novoItem.jsonAlterado = p['jsonAlterado'];
          switch (this.novoItem.acao) {
            case "Editar":
            var ddiffResult1 = this.textDiffService.getDiff(this.novoItem.jsonOriginal, this.novoItem.jsonAlterado);
            this.TextoDiferenca = this.sanitizer.bypassSecurityTrustHtml(ddiffResult1);
            break;
            case "Cadastro":
              this.TextoDiferenca = this.sanitizer.bypassSecurityTrustHtml(this.novoItem.jsonAlterado);
              break;
            case "Excluir":
              this.TextoDiferenca = this.sanitizer.bypassSecurityTrustHtml(this.novoItem.jsonOriginal);
              break;
            case  "Inativar":
              this.TextoDiferenca = this.sanitizer.bypassSecurityTrustHtml(this.novoItem.jsonAlterado);
              break;
            case "Ativar":
              this.TextoDiferenca = this.sanitizer.bypassSecurityTrustHtml(this.novoItem.jsonAlterado);
              break;
        }
         
        },
    (error) => {
      console.error('Erro ao excluir o item:', error);
    }
      );
  }
}

}

import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { orgao } from '../../../../modal/regional/orgao';
import { OrgaoService } from '../../../../service/orgao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'app-cadastro-listagem-orgao',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-orgao.component.html',
  styleUrl: './cadastro-listagem-orgao.component.scss'
})

export class CadastroListagemOrgaoComponent implements OnInit {
  data = [];
  public listaOrg: orgao[] = [];
  novoItem: orgao = new orgao();
  pesquisaDesc: string  = "";
  pesquisaOrg: string  = "";
  pesquisaCodCentralizado: string  = "";
  pesquisaCodigo: string  = "";

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orgaoService: OrgaoService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  ngOnInit() {
    this.Pesquisa();
  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa()
  }

  Pesquisa() {
    this.listaOrg = [];
    console.log(this.pesquisaCodCentralizado)
    this.orgaoService.getOrgPaginacao(this.pageIndex, this.pageSize,this.pesquisaCodigo, this.pesquisaCodCentralizado,this.pesquisaDesc).pipe(
    ).subscribe((response) => {
      this.data = response["Orgao"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new orgao(element['id'], element['codigo'], element['descricao'], element['codigoCentralizado'], element['nomeApresentacao']);
        this.listaOrg.push(p)
      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  excluirItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja excluir?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.orgaoService.deleteItem(id?.toString()).subscribe(
            (response) => {
              console.log('Item excluído com sucesso!');
              this.Pesquisa();
            },
            (error) => {
              console.error('Erro ao excluir o item:', error);
            }
          );
        }
      }
    });

  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser'){
        return true;
    }
    return false;
  }
  validarRoleSuper(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }
}

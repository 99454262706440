@if (!ModoApresentacao) {
<div [ngClass]="themeClass" class="main">
  <div class="sub-main">
    <div class="logo-1-2">
      <div class="logo">
        <img src="assets/NovoLogoSalaSituacao.png" (click)="retornoParaHome()" width="200" height="50">
      </div>
      <div class="logo">
        <img src="assets/poupatempo_indicadoreslogo_partida_modificada.png" (click)="retornoParaHome()" width="200"
          height="50">
      </div>
    </div>


    <div style="display: flex; justify-content: normal; align-items: end;">
    @if (login == null || login== undefined) {
    <button (click)="logar()" class="btn_govbr"> <i class="fad fa-user"
        style="margin-right: 10px;--fa-secondary-opacity: 1;"></i> Entrar com o gov.br</button>

    }
    @else{
    <div style="display: flex; justify-content: normal; align-items: end;">
      <button (click)="AbrirAlertas()"  [ngStyle]="{'background': numeroAlertas != 0 ? '#da1313f3' : '#9e9e9e'}" style="margin-left: 10px;margin-right: 5px;" class="btnNotificacao" type="button" aria-label="Notificações">
        <i data-feather="bell" aria-hidden="true" focusable="false" style="color: #fff;" class="far fa-bell fa-lg"></i>
        <small [ngStyle]="{'background-color': numeroAlertas != 0 ? '#d80b0b' : '#9e9e9e'}">{{numeroAlertas}}<ng-container *ngIf="numeroAlertas!=0">+</ng-container></small>
      </button>
      <div class="container">
        <!-- Btn-->
        <button class="btn_3"><span style="margin-left: 20px;">{{login}}</span>
          <ul class="dropdown">
            <!--<li class="active"><a href="#">Profile Information</a></li>
            <li><a href="#">Help</a></li>-->
            
            <li>
              <p>Modo da tela:</p>
              <div style="display: flex;">
                <button [ngClass]="VisualizacaoPadrao === 'DARK' ? '' : 'btn-2'"
                  style="width: 100%; border: 2px solid #9e9e9e;" (click)="AlterarModoTela('DARK')">
                  <p>Dark</p>
                </button>
                <button [ngClass]="VisualizacaoPadrao === 'LIGHT' ? '' : 'btn-2'"
                  style="width: 100%; border: 2px solid #9e9e9e;" (click)="AlterarModoTela('LIGHT')">
                  <p>Light</p>
                </button>
              </div>
            </li>
            <li><a (click)="deslogar()">Sair</a></li>
          </ul>
        </button>
      </div>
      
    </div>
    }

  </div>
</div>
</div>
}
<app-alerta-vagas  *ngIf="!router.url.includes('iniciar') && !router.url.includes('loading')" [DadosAlerta]="listaInd"></app-alerta-vagas>
<!-- </div> -->
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    Router,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from '../header/header.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AjudaComponent } from '../components/ajuda/ajuda.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilSharedDataService } from '../service/UtilSharedDataService';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        // HttpClientModule,
        // MatTooltipModule,
        // MatToolbarModule,
        // MatButtonModule,
        // FontAwesomeModule,
        // MatIconModule,
        // MatMenuModule,
        // MatFormFieldModule,
        // MatCardModule,
        // MatTableModule,
        // NoopAnimationsModule,

        CommonModule,
        NgOptimizedImage,
        RouterLink,
        RouterOutlet,
        RouterModule,
        RouterLinkActive,
        MatSidenavModule,
        // //COMPO
        HeaderComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent implements OnChanges, OnInit{
    @Input() collapsed = false;
    @Input() screenWidth = 0;
    ModoApresentacao: boolean = false;
    @Output() themeToggle = new EventEmitter<string>();
    // Captura dos temas dark
    isDarkTheme: boolean = false;
    //public corLight: string = 'linear-gradient(to top, #e7e7e7fd 10%, #ffffff 60%, #ffffff 98%)';
    public corDark: string = 'linear-gradient(to bottom, #2c282dfd 0%, #494d55 60%, #002864 98%)';
    public themeClass: string = this.collapsed? 'body-trimmed body': 'body-md-screen body';

  constructor(public router: Router, @Inject(DOCUMENT) private document: Document,private utilSharedDataService: UtilSharedDataService,
  public dialog: MatDialog)
 {
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      var NewVisu = localStorage.getItem('MODOTELA');
      this.isDarkTheme = NewVisu == null ? false: NewVisu =="LIGHT"? false : true;
    }
 }

 ngOnInit(): void {
    this.utilSharedDataService.modoApresentacao$.subscribe(boolean => {
      this.ModoApresentacao =boolean;
      if(boolean)
      {
        if(this.isDarkTheme)
        {
            this.themeClass = this.collapsed? 'body-trimmed dark-theme SemMargem': 'body-md-screen dark-theme SemMargem';
        }
        else
        {
            this.themeClass = this.collapsed? 'body-trimmed body SemMargem': 'body-md-screen body SemMargem';
        }
      }
      else
      {
        if(this.isDarkTheme)
        {
            this.themeClass = this.collapsed? 'body-trimmed dark-theme': 'body-md-screen dark-theme';
        }
        else
        {
            this.themeClass = this.collapsed? 'body-trimmed body': 'body-md-screen body';
        }
      }
  });
}
 
 ngOnChanges(changes: SimpleChanges) {
    console.log(this.isDarkTheme);
    if (changes['collapsed']) {
        if(this.isDarkTheme)
        {
            this.themeClass = this.collapsed? 'body-trimmed dark-theme': 'body-md-screen dark-theme';
        }
        else
        {
            this.themeClass = this.collapsed? 'body-trimmed body': 'body-md-screen body';
        }
    }
  }

    public toggleTheme(theme: string) {
        console.log(theme);
        if(theme=="LIGHT")
        {
            this.isDarkTheme = false;
            this.themeClass = this.collapsed? 'body-trimmed body': 'body-md-screen body';
        }
        else if(theme == "DARK")
        {
            this.isDarkTheme = true;
            this.themeClass = this.collapsed? 'body-trimmed dark-theme': 'body-md-screen dark-theme';
        }
        this.themeToggle.emit(theme);
    }

    public getBodyClass() {
        let styleClass = '';
        if (this.collapsed && this.screenWidth > 768) {
            styleClass = 'body-trimmed';
        } else if (
            this.collapsed &&
            this.screenWidth <= 768 &&
            this.screenWidth > 0
        ) {
            styleClass = 'body-md-screen';
        }
        return styleClass;
    }

    shouldShowHelpButton(): boolean {
        const currentUrl = this.router.url;
        
        const allowedUrls = ['dashboard', 'iniciar','indicador-orgao','indicador-posto','indicador-detalhe',
        'indicador-fila','indicador-detalhe','vagas-disponiveis-regiao','vagas-disponiveis-posto',
        'vagas-disponiveis-orgao','vagas-disponiveis-servico'];
        return allowedUrls.some(url => currentUrl.includes(url));
      }

    
}

/*

 if( document ){
        document.body.classList.add("dark-theme")
        console.log("if-true ",document.body.classList.add("dark-theme"))
    } else {
        this.document.body.classList.remove("dark-theme")
    }


toggleDarkTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    if (this.isDarkTheme) {
        this.document.body.classList.add('dark-theme');
      } else {
        this.document.body.classList.remove('dark-theme');
      }
  }
*/

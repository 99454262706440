import { Injectable } from '@angular/core';
import { ClasseControlePermissaoUsers } from '../modal/ControlePermissaoUsers';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { environment } from '../../environments/environments';

@Injectable({
    providedIn: 'root',
})
export class ControlePerfilUsersService {

    apiBackEnd = environment.API_URL
    apiContext = "ss.usuario";
    apiUrl = "/usuarioFindAll";

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: false
    };

    constructor(private http: HttpClient) {}

    public findPaginacaoUsuarios(pageNumber: number, pageSize: number, filtroNome: string, filtroCPF:string): Observable<any>{
        let headers =  new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.get(this.apiBackEnd+this.apiContext+'/findPaginacaoUsuarios?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroNome='+filtroNome+'&filtroCPF='+filtroCPF,
        { headers: headers, withCredentials: false});
    }

    public usuarioFindId(userPerfilID: any): Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true
          };
        return this.http.get<any>( `${this.apiBackEnd}${this.apiContext}/usuarioFindId/${userPerfilID}`, httpOptions)
          .pipe( retry(1), catchError(this.handleError) )
    }

    public cadastroUsuario(user: any): Observable<any> {
        let login = localStorage.getItem('login');
        console.log("Passei aqui editarPerfilUsuario() ", user)
        if (!user.id) {
            throw new Error("ID do usuário é undefined, não pode atualizar!");
        }
        return this.http.post<any>(
            `${this.apiBackEnd}${this.apiContext}/cadastroUsuario?nomeUsuario=`+login, JSON.stringify(user), this.httpOptions)
            .pipe( retry(1), catchError(this.handleError) )
      }

    public editarUsuarioID(id:any, user: any): Observable<any> {
        let login = localStorage.getItem('login');
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true
          };
          var json = JSON.stringify(user)
        return this.http.post<any>(
            `${this.apiBackEnd}${this.apiContext}/editarUsuarioID?nomeUsuario=`+login+`&id=${id}`, json, httpOptions)
            .pipe( retry(1), catchError(this.handleError) )
      }

    public getPerfilUsuariosPAGE(): Observable<any> {
        return this.http.get(
            `${this.apiBackEnd}${this.apiContext}/usuarioFindAll`, this.httpOptions)
    }

    public getPerfilUsuarios(): Observable<ClasseControlePermissaoUsers[]> {
        return this.http.get<{Usuario: ClasseControlePermissaoUsers[]}>(
            `${this.apiBackEnd}${this.apiContext}/usuarioFindAll`, this.httpOptions)
            .pipe(
                map(response => response.Usuario)
            );
    }

    public handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Cliente-side error
            errorMessage = `Erro do cliente: ${error.error.message}`;
        } else if (error.error && error.error.message) {
            // Verificação adicional para erros no lado do servidor
            errorMessage = `Erro do servidor: ${error.error.message}`;
        } else {
            // Resposta de erro do servidor sem um corpo de erro específico
            errorMessage = `Código do erro: ${error.status}, mensagem: ${error.statusText}`;
        }
        console.error(errorMessage);
        return throwError(() => new Error(errorMessage));
    }

    public saveUsuario(user: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true
          };
          var json = JSON.stringify(user)
          let login = localStorage.getItem('login');
        return this.http.post<any>(
            `${this.apiBackEnd}${this.apiContext}/cadastroUsuario?nomeUsuario=`+login, json, httpOptions)
            .pipe( retry(1), catchError(this.handleError) )
      }
}

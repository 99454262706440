import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { TabelaService } from '../../../../service/tabela.service';
import { tabela } from '../../../../modal/tabela';

@Component({
  selector: 'app-tabela-cadastro',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './tabela-cadastro.component.html',
  styleUrl: './tabela-cadastro.component.scss'
})

export class TabelaCadastroComponent implements OnInit {
  data = [];
  public lista: tabela[] = [];
  totalPages = 1;
  descricao: string | undefined;

  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;
  @ViewChild('mySelectFiltroStatus') mySelectFiltroStatus: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tabelaService: TabelaService,
    public dialog: MatDialog,
    private authService: AuthService,
    private oauthService: OAuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }

  }

  ngOnInit() {
    this.Pesquisa();
  }

  DeletarTabela(id: string | null) {
    if (id != null) {
      this.tabelaService.deletarItem(id).subscribe((situacao: any) => {
        this.Pesquisa();
      });
    }
  }

  CadastrarTabela() {
    let newTabela = new tabela(null,
      this.descricao);
    this.tabelaService.adicionarItem(newTabela).subscribe((situacao: any) => {
      alert("Salvo com sucesso!");
      this.Pesquisa();
      this.descricao = "";
    });
  }

  Pesquisa() {
    this.lista = [];
    this.tabelaService.get().pipe(
    ).subscribe((response) => {
      this.lista = []
      this.data = response["Tabela"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new tabela(element['id'],
          element['descricao']);
        this.lista.push(p)

      });
    });
  }
}

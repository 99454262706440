<section>
  <section style="height: auto;">
    <nav>
      <ol class="cd-breadcrumb custom-separator">
        <table>
          <tbody>
            <tr>
              <td class="nomeTela">
                @if(this.url.includes("/posto-cadastro?IdEditar")){
                Edição de Posto
                }
                @else{
                Cadastro de Posto
                }
              </td>
            </tr>
          </tbody>
        </table>
      </ol>
    </nav>
  </section>
  <main>
    <div *ngIf="MensagemError != ''" class="alert alert-danger" role="alert">
      {{MensagemError}}
    </div>
    @if(verificarCodigo == true){
    <div class="alert alert-danger" role="alert">
      Este <b>Código</b> já existe!
    </div>
    }
    @if(verificarCodigoCentralziado == true){
    <div class="alert alert-danger" role="alert">
      Este <b>Código Centralizado</b> já existe!
    </div>
    }
    <div>
      <div class="cd-breadcrumb custom-separator" role="alert">
        <i class="fa-solid fa-circle-info"></i> Obs: Todos os campos com asterisco são campos obrigatórios!
      </div>
    </div>
    <section class="main-section">
      <form class="form-class" data-toggle="collapse" (click)="validacao()">
        <input type="text" class="form-control" id="id" name="id" [(ngModel)]="novoItem.id" required
          style="display: none;">
        <div class="form-div p-3">
          <label for="codigo" class="form-label">Codigo do Posto *</label>
          <input type="number" class="form-control" id="codigo" name="codigo" [(ngModel)]="novoItem.codigo"
            (keypress)="maxLength($event)" required>
        </div>
        <div class="form-div p-3">
          <label for="codigoCentralizado" class="form-label">Codigo Centralizado *</label>
          <input type="number" class="form-control" id="codigoCentralizado" name="codigoCentralizado"
            [(ngModel)]="novoItem.codigoCentralizado" (keypress)="maxLength($event)" required>
        </div>

        <div class="form-div p-3">
          <label for="descricao" class="form-label">Nome do Posto *</label>
          <input type="text" class="form-control" id="descricao" name="descricao" [(ngModel)]="novoItem.descricao"
            required>
          <!-- <div id="nomePosto" class="form-text">Insira o nome do posto.</div> -->
        </div>

        <div class="form-div p-3">
          <label for="descricao" class="form-label">Inativo?</label>
          <input type="checkbox" id="inativo" name="inativo" [(ngModel)]="novoItem.inativo" required>
        </div>
        <div class="form-div p-3">
          <label for="regiao" class="form-label">Região *</label>
          <select #mySelect name="select" class="form-select" (change)="onOptionChange(mySelect.value)">
            <option value="">Escolha a Região</option>
            <option *ngFor="let objeto of listaReg" value={{objeto.id}}>{{objeto.descricao}}</option>
          </select>
        </div>
        @if(AlterandoHorario == false){
        <div class="form-div p-3">
          <div class="alert alert-info" role="alert">
            <i class="fa-solid fa-circle-info"></i> Se os horários não forem informados, será considerado o horario
            padrão automaticamente: <b>Sábado</b>: das 9:00 as 14:00, <b>Segunda á Sexta</b>: das das 9:00 as 17:00!
          </div>
        </div>
        }
        @if(validarHorariosMaioresSS() == false){
        <div class="form-div p-3">
          <div class="alert alert-danger" role="alert">
            <i class="fa-solid fa-circle-info"></i> O horário inicio é maior que o horário fim de <b>Segunda a Sexta</b>
            !
          </div>
        </div>
        }
        @if(validarHorariosMaioresSab() == false){
        <div class="form-div p-3">
          <div class="alert alert-danger" role="alert">
            <i class="fa-solid fa-circle-info"></i> O horário inicio é maior que o horário fim de <b>Sábado</b> !
          </div>
        </div>
        }
        <div class="form-div p-3" style="display: flex; justify-content: space-around; padding: 0px !important;">
          <div class="form-div p-3" style="padding-bottom: 0px !important;">
            <label class="form-label">Segunda a Sexta</label>
          </div>
          <div class="form-div p-3" style="padding-bottom: 0px !important;">
            <label class="form-label">Sabado</label>
          </div>
        </div>

        <div class="form-div p-3" style="display: flex; justify-content: space-around;  padding-top: 0px !important;">
          <div class="form-div p-3" style="display: flex; justify-content: space-around;">
            <div>
              <label for="horarioInicioSS" class="form-label">Horario Inicio</label>
              <input type="text" mask="00:00" placeholder="HH:mm" class="form-control" id="horarioInicioSS"
                name="horarioInicioSS" [(ngModel)]="novoItem.horarioInicioSS" (keyup)="onKeyUp($event)"
                (keypress)="limiteHorario == true" required>
            </div>
            <div style="margin-left: 10px;">
              <label for="horarioFimSS" class="form-label">Horario Fim</label>
              <input type="text" mask="00:00" placeholder="HH:mm" class="form-control" id="horarioFimSS"
                name="horarioFimSS" [(ngModel)]="novoItem.horarioFimSS" (keyup)="onKeyUp($event)"
                (keypress)="limiteHorario == true" required>
            </div>
          </div>

          <div class="form-div p-3" style="display: flex;">
            <div>
              <label for="horarioInicioSab" class="form-label">Horario Inicio</label>
              <input type="text" mask="00:00" placeholder="HH:mm" class="form-control" id="horarioInicioSab"
                name="horarioInicioSab" [(ngModel)]="novoItem.horarioInicioSab" (keyup)="onKeyUp($event)"
                (keypress)="limiteHorario == true" required>
            </div>
            <div style="margin-left: 10px;">
              <label for="horarioFimSab" class="form-label">Horario Fim</label>
              <input type="text" mask="00:00" placeholder="HH:mm" class="form-control" id="horarioFimSab"
                name="horarioFimSab" [(ngModel)]="novoItem.horarioFimSab" (keyup)="onKeyUp($event)"
                (keypress)="limiteHorario == true" required>
            </div>
          </div>
        </div>

        <div class="div-button mb-3 p-3">

          <button id="class-button" class="btn btn-secondary"><a [routerLink]="['/posto-listagem']">Voltar</a></button>
          <button id="class-button" style="margin-right: 10px;" (click)="Limpar()"
            class="btn btn-secondary">Limpar</button>
          @if(lengthValorDigitado > 0 && lengthValorDigitado <= 4 || validarHorarios()==false ||
            validarHorariosMaioresSS()==false || validarHorariosMaioresSab()==false || verificarCodigo==true ||
            verificarCodigoCentralziado==true){ <button id="class-button" (click)="submitForm()" class="btn btn-primary"
            disabled>Salvar</button>
            }
            @else {
            <button id="class-button" (click)="submitForm()" class="btn btn-primary">Salvar</button>
            }

        </div>
      </form>
    </section>
  </main>
</section>
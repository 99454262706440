import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { orgao } from '../../../../modal/regional/orgao';
import { servico } from '../../../../modal/regional/servico';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { HeaderComponent } from '../../../../header/header.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from '../../../../service/auth.service';
import { LoadingIndicadoresService } from '../../../../loading-indicadores/loading-indicadores.service';

@Component({
  selector: 'app-cadastro-adicionar-posto',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule,
    NgxMaskDirective
  ],
  templateUrl: './cadastro-adicionar-posto.component.html',
  styleUrl: './cadastro-adicionar-posto.component.scss',
  providers: [provideNgxMask({ /* opções de cfg */ })]
})

export class CadastroAdicionarPostoComponent implements OnInit {
  data = [];
  dataOrg = [];
  AlterandoHorario = false;
  public listaPos: posto[] = [];
  public listaReg: regiao[] = [];
  novoItem: posto = new posto();
  public MensagemError = "";
  limiteHorario: boolean = true;
  lengthValorDigitado: any;
  touched: boolean = false;
  verificarCodigo: boolean = false;
  verificarCodigoCentralziado: boolean = false;
  url: any;

  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private postoService: PostoService,
    private regiaoService: RegiaoService,
    private loadingService: LoadingIndicadoresService,
    public dialog: MatDialog,
    private oauthService: OAuthService,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  submitForm() {
    this.loadingService.show();
    if (this.novoItem.codigo == undefined
      || this.novoItem.descricao == ""
      || this.novoItem.codigoCentralizado == ""
      || this.novoItem.codigoCentralizado == undefined
      || this.novoItem.descricao == undefined
      || this.novoItem.codigo == "") {
      this.loadingService.hidePosto();
      this.MensagemError = ("Digite todos os campos obrigatorios!")

      return;
    }

    // Remover `horarioFimSS` se estiver vazio

    if (this.checkVazio(this.novoItem.horarioInicioSS) &&
      this.checkVazio(this.novoItem.horarioFimSS) &&
      this.checkVazio(this.novoItem.horarioInicioSab) &&
      this.checkVazio(this.novoItem.horarioFimSab)) {
      this.novoItem.horarioInicioSS = "09:00";
      this.novoItem.horarioFimSS = "17:00";
      this.novoItem.horarioInicioSab = "09:00";
      this.novoItem.horarioFimSab = "14:00";
    }

    this.MensagemError = "";
    console.log('Novo item', this.novoItem);
    this.postoService.adicionarItem(this.novoItem).subscribe((situacao: any) => {
      console.log(situacao)
      console.log('Novo item adicionado com sucesso', this.novoItem);
      this.novoItem = new posto();
      this.mySelect.nativeElement.value = '';
      this.authService.atualizarListaPostosERegiaoUsuario();
      this.loadingService.hidePosto();
      if (situacao.status == "edição") {
        this.router.navigate(['posto-listagem'], {
          state: { 'edicao': true }
        });
      }
      if (situacao.status == "salvar") {
        this.router.navigate(['posto-listagem'], {
          state: { 'salvar': true }
        });
      }
    }, error => {
      if (error.statusText == "Conflict") {
        this.MensagemError = error.error.erro;
      }
      this.loadingService.hidePosto();
      console.error('Erro ao adicionar novo item:', error);
    });
  }

  Limpar() {
    this.novoItem = new posto();
    this.novoItem.id = null;
  }

  onOptionChange(newValue: string) {
    this.novoItem.regiao = this.listaReg.find(x => x.id == newValue);
    console.log('Novo item', this.novoItem, newValue, this.listaReg);
  }
  ngOnInit() {

    this.url = this.router.url;
    this.regiaoService.getRegiao().pipe(
    ).subscribe((response) => {
      this.data = response["Regiao"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        this.listaReg.push(new regiao(element['id'], element['codigo'], element['descricao']))
      });
      this.listaReg = this.listaReg.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;


      });

      this.novoItem.regiao = this.listaReg[0];

      this.route.queryParams.subscribe(params => {
        let idEditar = params['IdEditar'];
        if (idEditar != undefined)
          this.AbrirEditarItem(idEditar);
      });
    });


  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.postoService.editarItem(id?.toString()).subscribe(
        (response) => {
          console.log(response);
          let p = response["Posto"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.codigoCentralizado = p['codigoCentralizado'];
          this.novoItem.descricao = p['descricao'];
          this.novoItem.horarioInicioSS = p['horarioInicioSS'];
          this.novoItem.horarioFimSS = p['horarioFimSS'];
          this.novoItem.horarioInicioSab = p['horarioInicioSab'];
          this.novoItem.horarioFimSab = p['horarioFimSab'];
          if (this.checkVazio(this.novoItem.horarioInicioSS) &&
            this.checkVazio(this.novoItem.horarioFimSS) &&
            this.checkVazio(this.novoItem.horarioInicioSab) &&
            this.checkVazio(this.novoItem.horarioFimSab)) {
              this.AlterandoHorario = false;
          }
          else
            this.AlterandoHorario = true;
          if (p['inativo'] != undefined)
            this.novoItem.inativo = p['inativo'];
          if (p['regiao'] != undefined) {
            this.novoItem.regiao = new regiao(p['regiao']['id'], p['regiao']['codigo'], p['regiao']['descricao'])
            this.mySelect.nativeElement.value = '' + p['regiao']['id'];
          }
          if (p['orgaos'] != undefined) {
            this.novoItem.orgaos = [];

            this.dataOrg = p['orgaos'];
            this.dataOrg.forEach(element => {
              this.novoItem.orgaos.push(new orgao(element['id'], element['codigo'], element['descricao'], element['codigoCentralizado']));
            });
          }
          if (p['servicos'] != undefined) {
            this.novoItem.servicos = [];

            this.dataOrg = p['servicos'];
            this.dataOrg.forEach(element => {
              var s = new servico(element['id'], element['codigo'], element['descricao'], element['codigoCentralizado']);
              if (element['orgao'] != undefined) {
                var o = new orgao(element['orgao']['id'], element['orgao']['codigo'], element['orgao']['descricao'], element['orgao']['codigoCentralizado']);
                s.orgao = o;
              }
              this.novoItem.servicos.push(s);
            });
          }
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  maxLength(e: any) {
    let charCode = e.charCode ? e.charCode : e.keyCode;
    const valorDigitado = e.target.value;
    // charCode 8 = backspace
    // charCode 9 = tab

    if (charCode != 8 && charCode != 9) {
      // charCode 48 equivale a 0
      // charCode 57 equivale a 9
      let max = 4;

      if ((charCode < 48 || charCode > 57) || (e.target.value.length >= max)) return false;
    }
    return true
  }

  onKeyUp(event: any) {
    const valorDigitado = event.target.value;
    if (event == null || valorDigitado == "") {

      if (!this.checkVazio(this.novoItem.horarioInicioSS) ||
        !this.checkVazio(this.novoItem.horarioFimSS) ||
        !this.checkVazio(this.novoItem.horarioInicioSab) ||
        !this.checkVazio(this.novoItem.horarioFimSab)) {
        this.AlterandoHorario = true;
      }
      else {
        this.AlterandoHorario = false;
      }
      this.limiteHorario = true;
    }
    else {
      this.AlterandoHorario = true;
      this.touched = true;
      this.lengthValorDigitado = valorDigitado.length;
      if (valorDigitado.charAt(0) <= 2) {
        this.limiteHorario = true;
        if (valorDigitado.charAt(0) == 2) {
          if (valorDigitado.charAt(1) <= 3) {
            this.limiteHorario = true;
            if (valorDigitado.charAt(3) > 5) {
              this.limiteHorario = false;
            } else {
              this.limiteHorario = true;
            }
          } else {
            this.limiteHorario = false;
          }
        } else if (valorDigitado.charAt(3) > 5) {
          this.limiteHorario = false;
        }
      } else if (valorDigitado.charAt(0) > 2) {
        this.limiteHorario = false;
      }
      else {
        this.limiteHorario = true;
      }

    }
  }

  checkVazio(valor: string | undefined) {
    if (valor == "" || valor == undefined)
      return true;
    else
      return false;
  }

  validarHorarios() {
    if (this.checkVazio(this.novoItem.horarioInicioSS) &&
      this.checkVazio(this.novoItem.horarioFimSS) &&
      this.checkVazio(this.novoItem.horarioInicioSab) &&
      this.checkVazio(this.novoItem.horarioFimSab)) {
      return true;
    }
    else {
      if (this.touched == true) {
        if (this.checkVazio(this.novoItem.horarioInicioSS) ||
          this.checkVazio(this.novoItem.horarioFimSS) ||
          this.checkVazio(this.novoItem.horarioInicioSab) ||
          this.checkVazio(this.novoItem.horarioFimSab)) {
          return false;
        }
        else {
          return true;
        }
      } else {
        return true;
      }
    }
  }


  validarHorariosMaioresSS() {
    var horarioInicioSSNumber = Number(this.novoItem.horarioInicioSS);
    var horarioFimSSNumber = Number(this.novoItem.horarioFimSS);
    if (horarioInicioSSNumber > horarioFimSSNumber) {
      return false;
    } else {
      return true;
    }
  }

  validarHorariosMaioresSab() {
    var horarioInicioSab = Number(this.novoItem.horarioInicioSab)
    var horarioFimSab = Number(this.novoItem.horarioFimSab);
    if (horarioInicioSab > horarioFimSab) {
      return false;
    } else {
      return true;
    }
  }

  validacao() {
    if (!this.url.includes("/posto-cadastro?IdEditar")) {
      if (this.novoItem.codigo != undefined) {
        console.log(this.novoItem.codigo, this.novoItem.codigoCentralizado);
        this.postoService.findByCodigo(this.novoItem.codigo).pipe().subscribe((response) => {
          console.log(response);
          var Ret = response["Posto"];
          if (Ret == "Sem Cadastro para Codigo") {
            this.verificarCodigo = false;
          }
          else
            this.verificarCodigo = true;
        }, () => {
          this.verificarCodigo = false;
        })

        this.postoService.findByCodigoCentralizado(this.novoItem.codigoCentralizado).pipe().subscribe((response) => {
          console.log(response);
          var Ret = response["Posto"];
          if (Ret == "Sem Cadastro para Codigo") {
            this.verificarCodigoCentralziado = false;
          }
          else
            this.verificarCodigoCentralziado = true;
        }, () => {

        })
      }
    }
  }

}

import { CommonModule, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { IndicadorEspecialService } from '../../../service/IndicadorEspecial.service';
import { indicadorAlertaPermanencia } from '../../../modal/indicadorAlertaPermanencia';
import { StatusIndicadorAlerta } from '../../../utilidades/enum/status.indicador.alerta.enum';
import { indicadorAlertaPermanenciaService } from '../../../service/indicadorAlertaPermanencia.service';
import { posto_simple } from '../../../modal/posto_simple';
import { mensagem } from '../../../modal/mensagem';
import { DateTimeFormatPipe } from '../../../utilidades/date-time-format.pipe';
import { TimeFormatPipe } from '../../../utilidades/time-format.pipe';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { FormControl, FormsModule } from '@angular/forms';
import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { StatusCorIndicadorAlerta } from '../../../utilidades/enum/status.cor.indicardor.alerta.enum';
import $ from 'jquery'; // Import jQuery
import { MultipleSelectionSelectAllComponent } from '../../../utilidades/multiple-selection-select-all/multiple-selection-select-all.component';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PostoService } from '../../../service/posto.service';
import { RegiaoService } from '../../../service/regiao.service';
import { LogosBase64 } from '../../../utilidades/logosBase64'
import { Console } from 'console';
@Component({
  selector: 'app-cidadao-espera-criti-roxo',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    FormsModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    MultipleSelectionSelectAllComponent,
    MatFormFieldModule, MatDatepickerModule
  ],
  templateUrl: './cidadao-espera-criti-roxo.component.html',
  styleUrl: './cidadao-espera-criti-roxo.component.scss',
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    TimeFormatPipe, DateTimeFormatPipe, provideNativeDateAdapter()]
})

export class CidadaoEsperaCriticidadeRoxoComponent implements OnInit {
  public SemDados: boolean = false;
  public StatusIndicadorAlerta = StatusIndicadorAlerta;
  public statusCorIndicadorAlerta = StatusCorIndicadorAlerta;
  larguraTela: number = window.innerWidth;
  login: string | null | undefined = null;
  data = [];
  dataMensagem = [];
  public listaIndPosto: posto_simple[] = [];
  public listaTotalIndPosto: posto_simple[] = [];
  listaPostos: any = [];
  listaRegiao: any = [];
  RegiaoSelecionada: any;
  PostosSelecionados: any[] = [];
  @ViewChild("table1") table2: any;
  @ViewChild('table1', { static: false }) table: ElementRef<HTMLTableElement> | undefined;
  usuario: any;
  public filtroDataInicio = new Date();
  public filtroDataFim = new Date(new Date().setDate(new Date().getDate() + 1));

  constructor(private route: ActivatedRoute, private router: Router,
    private IndAlertaPermanenciaService: indicadorAlertaPermanenciaService, @Inject(DOCUMENT) private document: Document,
    private postoService: PostoService, private regiaoService: RegiaoService,
    public timeFormatPipe: TimeFormatPipe,
    public dateTimeFormatPipe: DateTimeFormatPipe) {
    try {
      const localStorage = document.defaultView?.localStorage;
      if (localStorage)
        this.login = localStorage.getItem('login');
      if (this.login == null || this.login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }
  ngOnInit() {
    let login = localStorage.getItem('login');
    this.usuario = localStorage.getItem('salaSituacao');
    this.listarPostos();
    this.listarRegiao();
    // Roteamento de telas
    this.Pesquisa();
  }
  processarInfo(info: any) {
    this.PostosSelecionados = info;
  }

  listarPostos() {
    let sala: any = localStorage.getItem('salaSituacao');
    sala = JSON.parse(sala);
    this.postoService.getPostoUsuario(this.usuario).pipe().subscribe((result: any) => {
      result.Posto.forEach((posto: any) => {
        this.listaPostos.push(posto);
      });

      this.listaPostos.sort(function (a: any, b: any) {
        if (a.descricao > b.descricao) {
          return 1;
        }
        if (a.descricao < b.descricao) {
          return -1;
        }
        return 0;
      })
    })
  }
  listarRegiao() {
    this.regiaoService.getRegiaoUsuario(this.usuario).pipe().subscribe((element: any) => {
      console.log(element);
      element.Regiao.forEach((regiao: any) => {
        this.listaRegiao.push(regiao);
      });
      this.listaRegiao.sort(function (a: any, b: any) {
        if (a.descricao > b.descricao) {
          return 1;
        }
        if (a.descricao < b.descricao) {
          return -1;
        }
        return 0;
      })
    })
  }

  onSelecionar(valor: string) {
    this.RegiaoSelecionada = valor; // Atualiza o valor quando o select muda
  }

  fireEvent() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table2.nativeElement
    );

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* Save to file */
    XLSX.writeFile(wb, "SheetJS.xlsx");
  }

  Pesquisa() {
    console.log(this.filtroDataInicio.getTime(),this.filtroDataFim.getTime(), this.PostosSelecionados.toString());
    this.listaIndPosto = [];
    var retrievedObject = localStorage.getItem('salaSituacao');
    if (retrievedObject != null) {
      var sala = JSON.parse(retrievedObject);
      console.log(sala['postos']);
      this.IndAlertaPermanenciaService.getAllSemAbertas(sala['postos'], this.filtroDataInicio.getTime(),this.filtroDataFim.getTime(), this.PostosSelecionados.toString()).pipe(
      ).subscribe((response) => {
        this.data = response["IndicadorAlertaPermanencia"];
        console.log(response);
        this.data.forEach(element => {
          var PostoNaLista = this.listaIndPosto.find(x => x.id == element['posto']['id']);
          if (PostoNaLista == undefined) {
            let p = new posto_simple(element['posto']['id'], element['posto']['descricao']);
            this.dataMensagem = element["mensagens"];
            var M_i = 0;
            var listaMensagem: mensagem[] = [];
            if (this.dataMensagem != undefined)
              this.dataMensagem.forEach(el_mensagem => {
                var m = new mensagem(M_i, el_mensagem["mensagem"], el_mensagem["tipoMensagem"], el_mensagem["loginCadastro"], el_mensagem["dataCadastro"]);
                listaMensagem.push(m);
              });
            let i = new indicadorAlertaPermanencia(
              element['id'],
              element['data_fim'],
              element['dataInicio'],
              element['tempo'],
              element['qtdCidadaoEmEspera'],
              element['status'],
              element['descFila'],
              element['qtdPontosAbertos'],
              p,
              listaMensagem);
            p.indicadorAlertaPermanencia = [];
            p.indicadorAlertaPermanencia.push(i);
            this.listaIndPosto.push(p)
          }
          else {
            this.dataMensagem = element["mensagens"];
            var M_i = 0;
            var listaMensagem: mensagem[] = [];
            if (this.dataMensagem != undefined)
              this.dataMensagem.forEach(el_mensagem => {
                var m = new mensagem(M_i, el_mensagem["mensagem"], el_mensagem["tipoMensagem"], el_mensagem["loginCadastro"], el_mensagem["dataCadastro"]);
                listaMensagem.push(m);
              });
            let i = new indicadorAlertaPermanencia(
              element['id'],
              element['data_fim'],
              element['dataInicio'],
              element['tempo'],
              element['qtdCidadaoEmEspera'],
              element['status'],
              element['descFila'],
              element['qtdPontosAbertos'],
              PostoNaLista,
              listaMensagem);
            if (PostoNaLista.indicadorAlertaPermanencia != undefined)
              PostoNaLista.indicadorAlertaPermanencia.push(i);
            else {
              PostoNaLista.indicadorAlertaPermanencia = [];
              PostoNaLista.indicadorAlertaPermanencia.push(i);
            }
          }
        });
        this.listaIndPosto = this.listaIndPosto.sort((a, b) => {
          if (a.descricao < b.descricao) return -1;
          if (a.descricao > b.descricao) return 1;
          return 0;
        });

        this.listaTotalIndPosto = this.listaIndPosto;
      });


    }
  }

  filtrarPesquisa() {
    this.Pesquisa();
  }

  generateTableBody() {
    const body: any[] = [];

    // Cabeçalho da Tabela
    body.push([
      { text: 'Posto', style: 'header' },
      { text: 'Fila', style: 'header' },
      { text: 'Data Início / Hora', style: 'header' },
      { text: 'Data Fim / Hora', style: 'header' },
      { text: 'Tempo', style: 'header' },
      { text: 'Cidadão na Espera', style: 'header' },
      { text: 'Pontos Abertos', style: 'header' }
    ]);

    // Linhas de Dados
    this.listaIndPosto.forEach(PInd => {
      if (PInd.indicadorAlertaPermanencia != undefined) {
        var len = PInd.indicadorAlertaPermanencia.length;
        PInd.indicadorAlertaPermanencia.forEach((fila, index) => {
          if (index === 0) {
            // Linha para o primeiro item
            body.push([
              { text: PInd.descricao, rowSpan: len * 2, style: 'header' },
              { text: fila.descFila, rowSpan: 2, style: 'header' },
              { text: this.dateTimeFormatPipe.transform(fila.dataInicio), style: 'tableHeader' },
              { text: this.dateTimeFormatPipe.transform(fila.data_fim), style: 'tableHeader' },
              { text: this.timeFormatPipe.transform(fila.tempo), style: 'tableHeader' },
              { text: fila.qtdCidadaoEmEspera, style: 'tableHeader' },
              { text: fila.qtdPontosAbertos, style: 'tableHeader' },
            ]);
          } else {
            // Linhas para os itens subsequentes do mesmo posto
            body.push([
              { text: "", style: 'header' },
              { text: fila.descFila, rowSpan: 2, style: 'header' },
              { text: this.dateTimeFormatPipe.transform(fila.dataInicio), style: 'tableHeader' },
              { text: this.dateTimeFormatPipe.transform(fila.data_fim), style: 'tableHeader' },
              { text: this.timeFormatPipe.transform(fila.tempo), style: 'tableHeader' },
              { text: fila.qtdCidadaoEmEspera, style: 'tableHeader' },
              { text: fila.qtdPontosAbertos, style: 'tableHeader' },
            ]);
          }
          var TextoStatus = "";
          if (fila.status === StatusIndicadorAlerta.AO_INCLUIR) {
            TextoStatus = "Status: Justificativa Pendente";
          }
          else if (fila.status === StatusIndicadorAlerta.PEDENTE_POSTO) {
            TextoStatus = "Status: Pendente com Posto";
          }
          else if (fila.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO) {
            if (fila.mensagens != undefined && fila.mensagens.length != 0)
              TextoStatus = "Status: Pendente com Validação        " + fila.mensagens[fila.mensagens.length - 1].mensagem;
          }
          else if (fila.status === StatusIndicadorAlerta.FECHADO) {
            if (fila.mensagens != undefined && fila.mensagens.length != 0)
              TextoStatus = "Status: Encerado        " + fila.mensagens[fila.mensagens.length - 1].mensagem;
          }

          // Linha para o status e mensagens
          body.push([
            '', '', {
              text: TextoStatus, colSpan: 5, style: fila.status === StatusIndicadorAlerta.AO_INCLUIR ? 'StatusAoIncluir' :
                fila.status === StatusIndicadorAlerta.PEDENTE_POSTO ? 'StatusPendentePosto' :
                  fila.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO ? 'StatusPendenteValidacao' :
                    fila.status === StatusIndicadorAlerta.FECHADO ? 'StatusEncerrado' : ''
            },
            '', '', '', ''
          ]);
        });
      }
    });

    return body;
  };

  exportTableToPDF() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
    const formattedTime = `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`;
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;

    const docDefinition : any=  {
      content: [
        {
          columns: [
              {
                  image: LogosBase64.logo1,
                  width: 100,
                  alignment: 'left',
                  margin: [0, 0, 10, 0]
              },
              {
                width: "*", // Ocupa o espaço do meio
                text: "" // Espaço vazio entre os logos
              },
              {
                width: "auto", // Ajusta ao conteúdo dos logos
                alignment: "right",
                columnGap: 35, // Espaço fixo entre os logos
                columns: [
                    {
                        image: LogosBase64.logo2,
                        width: 60,
                       
                    },
                    {
                        image: LogosBase64.logo3,
                        width: 60,
                       
                    },
                    {
                        image: LogosBase64.logo4,
                        width: 60,
                       
                    }
                ]
            }
          ]
      },
      { 
        text: `Relatório: Relatório de Incidência`, 
        style: 'header' 
    },
    {                      
          columns: [
            
            { 
              width: "auto", // Garante que o login não fique comprimido
              text: `Emitido por: ${this.login}`+ ` - `+`Data ${formattedDateTime}`,
              style: 'posto', 
              fontSize: 7,
              margin: [0, 30, 0, 10] ,// Adiciona um espaçamento superior              
              noWrap: true // Impede a quebra do texto
            },                        
          ]         
    },   
        {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*', '*', '*'],
            body: this.generateTableBody()
          },
          layout: 'lightHorizontalLines' // Adiciona linhas horizontais leves
        },
        {
          text: [
              { text: "Rua Agueda Gonçalves, 240 - Taboão da Serra - SP, 06760-900 - Tel:(11)28456000 (PABX)\n", fontSize: 8, alignment: 'center' },
              { text: "Correspondência: Caixa Postal 25901 - CEP: 05513-970 - SÃO PAULO-SP\n", fontSize: 8, alignment: 'center' },
              { text: "www.prodesp.sp.gov.br - prodesp@prodesp.sp.gov.br", fontSize: 8, alignment: 'center' }
          ],
          margin: [0, 10, 0, 0] // Remove espaçamento entre as linhas
      }
      ],
      styles: {
        header: {
          fontSize: 6,
          bold: true,
          alignment: 'center' as const // Alinhar texto centralizado
        },
        tableHeader: {
          fontSize: 6,
          alignment: 'center' as const
        },
        StatusPendentePosto: {
          fillColor: '#' + this.statusCorIndicadorAlerta.PEDENTE_POSTO, // Cor de fundo
          color: '#000000', // Cor do texto
          fontSize: 6, // Tamanho da fonte
          alignment: 'center' as const // Alinhamento centralizado
        },
        StatusPendenteValidacao: {
          fillColor: '#' + this.statusCorIndicadorAlerta.PENDENTE_VALIDACAO, // Cor de fundo
          color: '#000000', // Cor do texto
          fontSize: 6, // Tamanho da fonte
          alignment: 'center' as const // Alinhamento centralizado
        },
        StatusAoIncluir: {
          fillColor: '#' + this.statusCorIndicadorAlerta.AO_INCLUIR, // Cor de fundo
          color: '#000000', // Cor do texto
          fontSize: 6, // Tamanho da fonte
          alignment: 'center' as const // Alinhamento centralizado
        },
        StatusEncerrado: {
          fillColor: '#' + this.statusCorIndicadorAlerta.FECHADO, // Cor de fundo
          color: '#000000', // Cor do texto
          fontSize: 6, // Tamanho da fonte
          alignment: 'center' as const // Alinhamento centralizado
        }
      },
      footer: (currentPage: number, pageCount: number) => {
        return {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'center',
            fontSize: 8,
            pageMargins: [20, 40, 20, 40] // Espaçamento do rodapé
        };
      }     
    };

    pdfMake.createPdf(docDefinition).download('RelatorioDeIncidencia_' + this.getTodayDate() + '.pdf');
  }

  exportTableToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');


    /// Define nome do usuário e data de geração
    const nomeUsuario = this.login; // Pegando o login do usuário
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()} ${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`;
    
          
    


    worksheet.columns = [
      { header: 'Posto', width: 30 },
      { header: 'Fila', width: 50 },
      { header: 'Data Início / Hora', width: 25 },
      { header: 'Data Fim / Hora', width: 25 },
      { header: 'Tempo', width: 15 },
      { header: 'Cidadão na Espera', width: 20 },
      { header: 'Pontos Abertos', width: 20 },
      { header: 'Justificativa', width: 50 } // Nova coluna para justificativa
    ];

    var IndexLinha = 2;
    this.listaIndPosto.forEach(posto => {
      const startRow = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;
      if (posto.indicadorAlertaPermanencia != undefined) {
        posto.indicadorAlertaPermanencia.forEach((fila, indexColuna) => {
          if (indexColuna == 0 && posto.indicadorAlertaPermanencia != undefined && posto.indicadorAlertaPermanencia.length > 1) {
            worksheet.mergeCells('' + this.getLetterFromNumber(1) + (IndexLinha) + ':' + this.getLetterFromNumber(1) + (IndexLinha + ((posto.indicadorAlertaPermanencia.length * 2) - 1)));
            worksheet.getCell(this.getLetterFromNumber(1) + IndexLinha).value = posto.descricao;
            worksheet.getCell(this.getLetterFromNumber(1) + IndexLinha).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
          else if (posto.indicadorAlertaPermanencia != undefined && posto.indicadorAlertaPermanencia.length == 1) {
            worksheet.mergeCells('' + this.getLetterFromNumber(1) + (IndexLinha) + ':' + this.getLetterFromNumber(1) + (IndexLinha + 1));
            worksheet.getCell(this.getLetterFromNumber(1) + IndexLinha).value = posto.descricao;
            worksheet.getCell(this.getLetterFromNumber(1) + IndexLinha).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }

          worksheet.mergeCells('' + this.getLetterFromNumber(2) + (IndexLinha) + ':' + this.getLetterFromNumber(2) + (IndexLinha + 1));
          worksheet.getCell(this.getLetterFromNumber(2) + IndexLinha).value = fila.descFila;
          worksheet.getCell(this.getLetterFromNumber(3) + IndexLinha).value = this.dateTimeFormatPipe.transform(fila.dataInicio);
          worksheet.getCell(this.getLetterFromNumber(4) + IndexLinha).value = this.dateTimeFormatPipe.transform(fila.data_fim);
          worksheet.getCell(this.getLetterFromNumber(5) + IndexLinha).value = this.timeFormatPipe.transform(fila.tempo);
          worksheet.getCell(this.getLetterFromNumber(6) + IndexLinha).value = fila.qtdCidadaoEmEspera;
          worksheet.getCell(this.getLetterFromNumber(7) + IndexLinha).value = fila.qtdPontosAbertos;
          for (let i = 2; i <= 7; i++) {
            worksheet.getCell(this.getLetterFromNumber(i) + IndexLinha).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }


          if (fila.status === StatusIndicadorAlerta.AO_INCLUIR) {
            worksheet.mergeCells('' + this.getLetterFromNumber(3) + (IndexLinha + 1) + ':' + this.getLetterFromNumber(7) + (IndexLinha + 1));
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).value = 'Status: Justificativa Pendente';
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.AO_INCLUIR }
            };
          }
          if (fila.status === StatusIndicadorAlerta.PEDENTE_POSTO) {
            worksheet.mergeCells('' + this.getLetterFromNumber(3) + (IndexLinha + 1) + ':' + this.getLetterFromNumber(7) + (IndexLinha + 1));
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).value = 'Status: Pendente com Posto';
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.PEDENTE_POSTO }
            };
          }
          if (fila.status === StatusIndicadorAlerta.PENDENTE_VALIDACAO) {
            worksheet.mergeCells('' + this.getLetterFromNumber(4) + (IndexLinha + 1) + ':' + this.getLetterFromNumber(7) + (IndexLinha + 1));
            if (fila.mensagens != undefined && fila.mensagens.length != 0)
              worksheet.getCell(this.getLetterFromNumber(4) + (IndexLinha + 1)).value = fila.mensagens[fila.mensagens.length -
                1].mensagem;
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).value = "Status: Pendente Validação";
            worksheet.getCell(this.getLetterFromNumber(4) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.PENDENTE_VALIDACAO }
            };
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.PENDENTE_VALIDACAO }
            };
          }
          if (fila.status === StatusIndicadorAlerta.FECHADO) {
            worksheet.mergeCells('' + this.getLetterFromNumber(4) + (IndexLinha + 1) + ':' + this.getLetterFromNumber(7) + (IndexLinha + 1));
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).value = "Status: Pendente Validação";
            if (fila.mensagens != undefined && fila.mensagens.length != 0)
              worksheet.getCell(this.getLetterFromNumber(4) + (IndexLinha + 1)).value = fila.mensagens[fila.mensagens.length -
                1].mensagem;
            worksheet.getCell(this.getLetterFromNumber(4) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.FECHADO }
            };
            worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: this.statusCorIndicadorAlerta.FECHADO }
            };
          }
          worksheet.getCell(this.getLetterFromNumber(3) + (IndexLinha + 1)).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          worksheet.getCell(this.getLetterFromNumber(4) + (IndexLinha + 1)).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          IndexLinha = IndexLinha + 2;

    
        });
              }
      });


      const ultimaLinha = worksheet.rowCount + 2;

       

        worksheet.getCell(`A${ultimaLinha}`).value = `Emitente: ${nomeUsuario} - ${formattedDate}`;
        worksheet.getCell(`A${ultimaLinha}`).font = { bold: true };
        worksheet.getCell(`A${ultimaLinha}`).alignment = { horizontal: 'center' };
        const cell = worksheet.getCell(`A${ultimaLinha}`);
        console.log(worksheet.getCell(`A${ultimaLinha}`))
        worksheet.mergeCells(`A${ultimaLinha}:G${ultimaLinha}`);

        // Define bordas para o rodapé
        worksheet.getCell(`A${ultimaLinha}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };    

        worksheet.getCell(`A${ultimaLinha+1}`).value = `Fonte : Incidência `;
        worksheet.getCell(`A${ultimaLinha+1}`).font = { bold: true };
        worksheet.getCell(`A${ultimaLinha+1}`).alignment = { horizontal: 'center' };
       
        worksheet.mergeCells(`A${ultimaLinha+1}:G${ultimaLinha+1}`);
                              

          
    // Salva o arquivo Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), 'RelatorioDeIncidencia_' + this.getTodayDate() + '.xlsx');
    });

  }



 

  exportTableToExcelInLine() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    /// Define nome do usuário e data de geração
    const nomeUsuario = this.login; // Pegando o login do usuário
    const today = new Date();
    const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()} ${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`;
    
  
    worksheet.columns = [
      { header: 'Posto', width: 30 },
      { header: 'Fila', width: 50 },
      { header: 'Data Início / Hora', width: 25 },
      { header: 'Data Fim / Hora', width: 25 },
      { header: 'Tempo', width: 15 },
      { header: 'Cidadão na Espera', width: 20 },
      { header: 'Pontos Abertos', width: 20 },
      { header: 'Status', width: 30 },
      { header: 'Justificativa', width: 50 } // Nova coluna
    ];
  
    let indexLinha = 2;
  
    this.listaIndPosto.forEach(posto => {
      if (posto.indicadorAlertaPermanencia) {
        posto.indicadorAlertaPermanencia.forEach(fila => {
          // Inserindo o posto em cada linha
          worksheet.getCell(`A${indexLinha}`).value = posto.descricao;
          worksheet.getCell(`B${indexLinha}`).value = fila.descFila;
          worksheet.getCell(`C${indexLinha}`).value = this.dateTimeFormatPipe.transform(fila.dataInicio);
          worksheet.getCell(`D${indexLinha}`).value = this.dateTimeFormatPipe.transform(fila.data_fim);
          worksheet.getCell(`E${indexLinha}`).value = this.timeFormatPipe.transform(fila.tempo);
          worksheet.getCell(`F${indexLinha}`).value = fila.qtdCidadaoEmEspera;
          worksheet.getCell(`G${indexLinha}`).value = fila.qtdPontosAbertos;
  
          // Definindo o status na última coluna
          let statusText = '';
          switch (fila.status) {
            case StatusIndicadorAlerta.AO_INCLUIR:
              statusText = 'Justificativa Pendente';
              worksheet.getCell(`H${indexLinha}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.statusCorIndicadorAlerta.AO_INCLUIR } };
              break;
            case StatusIndicadorAlerta.PEDENTE_POSTO:
              statusText = 'Pendente com Posto';
              worksheet.getCell(`H${indexLinha}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.statusCorIndicadorAlerta.PEDENTE_POSTO } };
              break;
            case StatusIndicadorAlerta.PENDENTE_VALIDACAO:
              statusText = 'Pendente Validação';
              worksheet.getCell(`H${indexLinha}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.statusCorIndicadorAlerta.PENDENTE_VALIDACAO } };
              break;
            case StatusIndicadorAlerta.FECHADO:
              statusText = 'Fechado';
              worksheet.getCell(`H${indexLinha}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: this.statusCorIndicadorAlerta.FECHADO } };
              break;
            default:
              statusText = 'Desconhecido';
          }
          worksheet.getCell(`H${indexLinha}`).value = statusText;

           // Preenchendo a coluna Justificativa
           if (fila.mensagens && fila.mensagens.length > 0) {
            // Concatena as mensagens em uma única string separada por vírgulas ou outra separação de sua escolha
            worksheet.getCell(`I${indexLinha}`).value = fila.mensagens.map(m => m.mensagem).join(', ');
          } else {
            worksheet.getCell(`I${indexLinha}`).value = '';
          }
  
          // Aplicar bordas em todas as colunas
          for (let i = 1; i <= 8; i++) {
            worksheet.getCell(this.getLetterFromNumber(i) + indexLinha).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
  
          indexLinha++;
        });
      }
    });


    const ultimaLinha = worksheet.rowCount + 2;

       

        worksheet.getCell(`A${ultimaLinha}`).value = `Emitente: ${nomeUsuario} - ${formattedDate}`;
        worksheet.getCell(`A${ultimaLinha}`).font = { bold: true };
        worksheet.getCell(`A${ultimaLinha}`).alignment = { horizontal: 'center' };
        const cell = worksheet.getCell(`A${ultimaLinha}`);
        console.log(worksheet.getCell(`A${ultimaLinha}`))
        worksheet.mergeCells(`A${ultimaLinha}:G${ultimaLinha}`);

        // Define bordas para o rodapé
        worksheet.getCell(`A${ultimaLinha}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };    

        worksheet.getCell(`A${ultimaLinha+1}`).value = `Fonte : Incidência `;
        worksheet.getCell(`A${ultimaLinha+1}`).font = { bold: true };
        worksheet.getCell(`A${ultimaLinha+1}`).alignment = { horizontal: 'center' };
       
        worksheet.mergeCells(`A${ultimaLinha+1}:G${ultimaLinha+1}`);
  
    // Salva o arquivo Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), 'RelatorioDeIncidencia_' + this.getTodayDate() + '.xlsx');
    });
  }
  


  getLetterFromNumber(num: number): string {
    const baseCharCode = 'A'.charCodeAt(0); // Código ASCII para 'A'
    return String.fromCharCode(baseCharCode + num - 1);
  }

  getTodayDate(): string {
    const date = new Date();
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
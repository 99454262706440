import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IndicadorFilaComponent } from '../indicador-fila/indicador-fila.component';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../modal/boxInfo';
//import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { CORES } from '../../modal/mock-cor';
import { ICONE } from '../../modal/icone';
import { breadcrumb } from '../../modal/breadcrumb';
import { IndicadorService } from '../../service/Indicador.service';
import { IndicadorOrgaoService } from '../../service/IndicadorOrgao.service';
import { TamanhoTituloService } from '../../cache/tamanho-indicador';
import { UtilSharedDataService } from '../../service/UtilSharedDataService';
@Component({
  selector: 'app-indicador-orgao',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    IndicadorFilaComponent,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule
  ],
  providers: [ICONE],
  templateUrl: './indicador-orgao.component.html',
  styleUrl: './indicador-orgao.component.scss'
})
export class IndicadorOrgaoComponent implements OnInit, OnDestroy  {

  // Construir um model com esses atributos -RegiaoCidadaoEsperaPostoModel-
  public nomeCidadaoPosto: string = "Posto Araçatuba"
  public valorIndicadorPosto: number = 144
  public corIindicadorPosto: string = "#fff"
  public IdTela: number = 0;
  public CcPosto: string = "";
  public Icone = "";
  dataOrg = [];
  public NomeTela = "";
  public listaOrg: boxInfo[] = [];
  cores = CORES;
  listabreadcrumb: breadcrumb[] = [];
  maiorTamanhoTitulo: number = 0;
  textInd: string | null = "";
  textPos: string | null = "";
  private _iniciou: boolean = false;
  public atualizando: boolean = false;
  intervalIds: any[] = [];
  set iniciou(value: boolean) {
    this._iniciou = value;
      this.verificarTitulo();
  }

  get iniciou(): boolean {
    return this._iniciou;
  }
  constructor(private route: ActivatedRoute, private utilSharedDataService: UtilSharedDataService, private icon: ICONE, private router: Router,private tamanhoTituloService: TamanhoTituloService, private indicadorService: IndicadorService, private OrgaoIndicadorService: IndicadorOrgaoService,) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    
    const breadcrumbString = window.localStorage.getItem('breadcrumb');
    if (breadcrumbString) {
      var bc = JSON.parse(breadcrumbString);
    }
    if (bc != undefined && bc.length != 0) {
      for (let index = 0; index < bc.length; index++) {
        const element = bc[index];
        this.listabreadcrumb.push(new breadcrumb(element.label, element.url));
      }
      this.listabreadcrumb.push(new breadcrumb("Orgão", ""));
      console.log(this.listabreadcrumb);
    }
    else {
      this.listabreadcrumb = [
        new breadcrumb("Dashboard", "/dashboard"),
        new breadcrumb("Posto", "/indicador-posto"),
        new breadcrumb("Orgão", "")
      ];
    }
  }
  maxHeight: number = 0;

  ngOnInit() {
    this.utilSharedDataService.updateModoApresentacao(false);
    let login = localStorage.getItem('login');
    this.maiorTamanhoTitulo = this.tamanhoTituloService.getMaiorTamanhoTitulo();
    if(this.maiorTamanhoTitulo== 0)
      this.maiorTamanhoTitulo = 31;
      this.route.queryParamMap.subscribe(params => {
        let tipotela =params.get('tipoTela');
        this.IdTela = tipotela ==null ? 0 :Number.parseInt(tipotela) ;
        this.textInd = params.get('textInd');
        this.textPos = params.get('textPos');
        let CodPosto =params.get('CcPosto');
        
        this.CcPosto = CodPosto==null? "" : CodPosto;
        var retrievedObject = localStorage.getItem('salaSituacao');
        if (retrievedObject != null) {
            var sala = JSON.parse(retrievedObject);
            console.log(retrievedObject);
            //console.table(sala['postos']);
            var postosUsuario = sala['postos'];
            if(!sala.postos.includes(Number.parseInt(this.CcPosto)))
              this.router.navigate(['/dashboard']);
        }
        else
          this.router.navigate(['/iniciar']);
      this.OrgaoIndicadorService.getOrgao(this.IdTela, this.CcPosto, login == undefined? "" : login).pipe(
      ).subscribe((response) => {
        console.log(response)
        this.dataOrg = response["orgoes"];
        let tempoAtualizacao = response["tempoAtualizacao"];
        this.NomeTela = 
          this.dataOrg[0]["nome"] + (this.textInd== null? "" :  this.textInd)
          + " (pelo posto " 
          + response["posto"]['posto']['descricao'] 
          + (this.textPos == null? "" :  this.textPos) + ")";
        var ord = this.dataOrg[0]["ordenacaoOrientacao"];
        if (ord == "DESC") {
          this.dataOrg.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
        } else if (ord == "CRES") {
          this.dataOrg.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
        }
        this.dataOrg.forEach(element => {
          this.listaOrg.push(new boxInfo(element['orgao']['codigoCentralizado'], element['orgao']['descricao'], response['linkRedirect'],
           this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"],this.cores.find(x => x.status == element["corFarol"])));
        });
        this.listaOrg.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);

        this.iniciou= true;
        this.setAtualizacaoindicadores(tempoAtualizacao);
      });
    });
  }
  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

setAtualizacaoindicadores(timeInterval: number) {
  let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
        
        this.atualizando = true;
        this.OrgaoIndicadorService.getOrgao(this.IdTela, this.CcPosto, login == undefined? "" : login).pipe(
        ).subscribe((response) => {
            this.dataOrg = response["orgoes"];
            console.log("ATAULIZA",this.dataOrg);
            var ord = this.dataOrg[0]["ordenacaoOrientacao"];
            if (ord == "DESC") {
              this.dataOrg.sort((a, b) => b['valor']['valorSemFormato'] - a['valor']['valorSemFormato']);
            } else if (ord == "CRES") {
              this.dataOrg.sort((a, b) => a['valor']['valorSemFormato'] - b['valor']['valorSemFormato']);
            }
            let i = 0;
            this.dataOrg.forEach(element => {
                var newind = new boxInfo(element['orgao']['codigoCentralizado'], element['orgao']['descricao'], response['linkRedirect'],
                 this.cores.find(x => x.status == element["cor"]), element['valor']['valorFormatado'], undefined, element["icone"],this.cores.find(x => x.status == element["corFarol"]));
                var oldind = this.listaOrg.find(x => x.id == element['orgao']['codigoCentralizado'] && x.nome == element['orgao']['descricao']);
                if(oldind != undefined){
                    oldind.valor = newind.valor;
                    oldind.cor = newind.cor;
                    oldind.nome = newind.nome;
                    oldind.icone = newind.icone;
                    oldind.corfarol = newind.corfarol;
                }
                else
                {
                  this.listaOrg.push(newind);
                }
            });
            this.listaOrg.sort((a, b) => a.cor.ordenacao - b.cor.ordenacao);
            this.atualizando = false;
        });
    }, (timeInterval * 1000));
    this.intervalIds.push(intervalId);
}
  verificarTitulo(): void {
    const tituloElement = document.querySelector('.title-card');
    if (tituloElement) {
      this.calcularAlturaMaxima();
    } else {
      setTimeout(() => {
        this.verificarTitulo();
      }, 10); 
    }
  }
  calcularAlturaMaxima(): void {
    // Calcula a altura máxima dos cartões
    this.listaOrg.forEach(ind => {
      const cardHeight = this.getHeightOfCard(ind);
      if (cardHeight > this.maxHeight) {
        this.maxHeight = cardHeight;
      }
    });
  }

  getHeightOfCard(ind: any): number {
    // Calcula a altura do cartão com base no conteúdo
    const titleElement = document.querySelector('#titulo_'+ind.id);
    const cardContentElement = document.querySelector('#content_'+ind.id);
    //console.log(titleElement);
    // Verifica se os elementos existem antes de medir suas alturas
    if (titleElement && cardContentElement) {
      const titleHeight = titleElement.clientHeight;
      const cardContentHeight = cardContentElement.clientHeight;

      // Adiciona margem superior e inferior para obter a altura total do cartão
      const cardHeight = titleHeight + cardContentHeight + 20; // Ajuste conforme necessário

      return cardHeight;
    }

    return 0; // Retorna 0 se os elementos não forem encontrados
  }

  navigateWithState(Link: string | null, CcOrgao: string, valor: string) {
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    var textOrg = this.extractMatches(valor);
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcPosto: this.CcPosto, CcOrgao: CcOrgao, textInd: this.textInd, textPos: this.textPos, textOrg: textOrg }
    });
  }
  navigateWithStateBC(Link: string | null) {
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, textInd: this.textInd }
    });
  }
  extractMatches(valor: string) : string {
    const regex = /\s\d+\/\d+/g;
    const matches = valor.match(regex);
    if (matches) {
      return " "+matches.join(' ').trim();
    }
    return "";
  }
}





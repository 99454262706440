<section>
    <section style="height: auto;">
      <nav>
          <ol class="cd-breadcrumb custom-separator">
              <table>
                  <tbody>
                      <tr>
                          <td class="nomeTela">
                            Listagem de motores de execução
                          </td>
                      </tr>
                  </tbody>
              </table>
          </ol>
      </nav>
  </section>
    <main>
      <section class="pesq-section">
  
        <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
  
          <table>
            <tbody>
              <tr>
                              
                <td>
                <td>
                  <div class="form-div p-3">
                    <label for="pesquisaNome" class="form-label">Nome:</label>
                    <input type="text" class="form-control" placeholder="Nome" id="pesquisaNome" name="pesquisaNome"
                      [(ngModel)]="pesquisaNome" required>
                  </div>
                </td>
                <td>
                  <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                  </div>
                </td>
                @if(validarRole() == true){
                <td style="position: fixed; bottom: 13px; right: 22px;">
                  <a [routerLink]="['/editar-motores']"><button id="class-button"
                      style="border-radius: 50%;width: 45px;height: 45px;" class="btn btn-success"><i
                        class="fas fa-plus"></i></button></a>
                </td>
                }
  
              </tr>
            </tbody>
          </table>
        </form>
  
      </section>
      <!--Tabela -->
      <section class="main-section-table">
        <table class="table">
          <!--toJsonBody-->
          <thead>
  
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Nome</th>
              <th scope="col">Status</th>
              <th scope="col">Data Alteração</th>       
              <th scope="col">Data Ultima Execução</th>            
              @if(validarRole() == true){
              <th scope="col">Funções</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (p of listarMotores; track $index) {
            <tr>
              <td>{{p.codigo}}</td>
              <td>{{p.nome}}</td>
              <td>{{ p.status ? 'Ativo' : 'Inativo' }}</td>
              <td>{{ p.dataAtualizacao ? (p.dataAtualizacao | date: 'dd/MM/yyyy') : 'Não teve Alteração' }}</td>
              <td>{{ p.dataUltimaExecucao ? (p.dataUltimaExecucao | date: 'dd/MM/yyyy HH:mm:ss') : 'Não execução ainda' }}</td>

              @if(validarRole() == true){
              <td><a class="btn btn-primary" style="margin-right: 10px;" [routerLink]="['/editar-motores']"
                  [queryParams]="{IdEditar: p.id}">Editar</a>
                <button style="margin-right: 10px;" class="btn btn-danger" (click)="excluirItem(p.id)">Excluir</button>
                @if(p.nomeExecucaoMotor != "" && p.nomeExecucaoMotor != null){
                <button class="btn btn-info"  (click)="ExecutarItem(p.nomeExecucaoMotor, p.avisoExecucaoMotor)">Executar</button>
                }
                @if(validarRole() == true){
                  <a [routerLink]="['/auditoria-listagem']"
                      [queryParams]="{id: p.codigo, tabela: 'MotorConfig'}"><button
                          class="btn btn-secondary" style="margin-left: 10px;"><i
                              class="fas fa-history"></i></button></a>
                  }
              </td>
              }
  
            </tr>
            }
          </tbody>
        </table>
  
        <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
          [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex" aria-label="Select page">
        </mat-paginator>
      </section>
  
  
    </main>
  </section>
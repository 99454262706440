import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, ContentChild, Inject, OnInit, TemplateRef, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { breadcrumb } from '../../../modal/breadcrumb';
import { IndicadorEspecialService } from '../../../service/IndicadorEspecial.service';
import { SubIndicadorEspecialCardVertical } from '../sub-indicador-especial-vertical/sub-indicador-especial-card-vertical.component';
import { indicador } from '../../../modal/Indicador';
import { indicadorDTO } from '../../../modal/dto/indicadorDTO';
import { valor } from '../../../modal/valor';
import { DisplayComponenteGrafico } from '../display-componente-grafico/display-componente-grafico.component';
import { cabecalhoDTO } from '../../../modal/dto/cabecalhoDTO';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilSharedDataService } from '../../../service/UtilSharedDataService';
import { LogosBase64 } from '../../../utilidades/logosBase64';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-indicador-especial',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    SubIndicadorEspecialCardVertical,
    DisplayComponenteGrafico
  ],
  templateUrl: './indicador-especial.component.html',
  styleUrl: './indicador-especial.component.scss'
})

export class IndicadorEspecialComponent implements OnInit {
  public NomeTela = "";
  public TipoVisualizacaoTela = "TABELA";
  public DisplayTela = "";
  public IdTela: number = 0;
  public CcPosto = "";
  tempoAtualizacao: any;
  intervalIds: any[] = [];
  public CcOrgao = "";
  public variaveis: string = "";
  textInd: string | null = "";
  textPos: string | null = "";
  textOrg: string | null = "";
  public ListaDentroLista: boolean = false;
  public SemDados: boolean = false;
  public SemOPrimeiroCampo: boolean = false;
  cabecalho: cabecalhoDTO[] = [];
  public listaMesa: indicadorDTO[] = [];
  public listaComponentesGraficos: valor[] = [];
  public atualizando: boolean = false;
  public nomeTipoTela: string = "";
  public PostoNome: string = "";
  data = []


  listabreadcrumb: breadcrumb[] = [];
  login: string | null | undefined = null
  constructor(private route: ActivatedRoute, private indicadorEspecialService: IndicadorEspecialService, private utilSharedDataService: UtilSharedDataService, private router: Router, @Inject(DOCUMENT) private document: Document) {
    try {
      const localStorage = document.defaultView?.localStorage;
      if (localStorage)
        this.login = localStorage.getItem('login');
      if (this.login == null || this.login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      var NewVisu = localStorage.getItem('VISUPADRAO');
      this.TipoVisualizacaoTela = NewVisu == null ? "TABELA" : NewVisu;
    }

    //console.log(NewVisu)

    const breadcrumbString = window.localStorage.getItem('breadcrumb');
    if (breadcrumbString) {
      var bc = JSON.parse(breadcrumbString);
    }
    if (bc != undefined && bc.length != 0) {
      for (let index = 0; index < bc.length; index++) {
        const element = bc[index];
        this.listabreadcrumb.push(new breadcrumb(element.label, element.url));
      }
    }
    else {
      this.listabreadcrumb = [
        new breadcrumb("Dashboard", "/dashboard"),
        new breadcrumb("Posto", "/indicador-posto"),
        new breadcrumb("Orgão", "/indicador-orgao")
      ];
    }
  }
  ngOnInit() {
    this.utilSharedDataService.updateModoApresentacao(false);
    let login = localStorage.getItem('login');
    this.cabecalho = [];
    // Roteamento de telas
    this.route.queryParamMap.subscribe(params => {
      let tipotela = params.get('tipoTela');
      this.IdTela = tipotela == null ? 0 : Number.parseInt(tipotela);
      this.textInd = params.get('textInd');
      this.textPos = params.get('textPos');
      this.textOrg = params.get('textOrg');
      let CodPosto = params.get('CcPosto');
      this.CcPosto = CodPosto == null ? "" : CodPosto;
      var retrievedObject = localStorage.getItem('salaSituacao');
      if (retrievedObject != null) {
        var sala = JSON.parse(retrievedObject);
        console.log(retrievedObject);
        //console.table(sala['postos']);
        var postosUsuario = sala['postos'];
        if (!sala.postos.includes(Number.parseInt(this.CcPosto)))
          this.router.navigate(['/dashboard']);
      }
      else
        this.router.navigate(['/iniciar']);
      let CodOrgao = params.get('CcOrgao');
      let varr = params.get('variaveis');
      this.variaveis = varr == null ? "" : varr;
      this.CcOrgao = CodOrgao == null ? "" : CodOrgao;
      this.indicadorEspecialService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, this.variaveis, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        console.log(response)
        this.data = response['indicadores'];
        if (this.data.length == 0) {
          this.SemDados = true;
        }
        else {
          this.tempoAtualizacao = response["tempoAtualizacao"];
          this.DisplayTela = response['visualizacaoDisplay'];
          if (response["orgao"] == null) {
            this.NomeTela =
              this.data[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }
          else {
            this.NomeTela =
              this.data[0]["nome"] + (this.textInd == null ? "" : this.textInd)
              + " (do orgão " + response["orgao"]['orgao']['descricao']
              + (this.textOrg == null ? "" : this.textOrg) + " do posto "
              + response["posto"]['posto']['descricao']
              + (this.textPos == null ? "" : this.textPos) + ")";
          }

          this.PostoNome = response["posto"]['posto']['descricao'];
          if (this.data[0]["nome"] == response["nomeTipoTela"])
            this.nomeTipoTela = response["nomeTipoTela"];
          else
            this.nomeTipoTela = this.data[0]["nome"] + " - " + response["nomeTipoTela"];
          this.listabreadcrumb.push(new breadcrumb("" + response["nomeTipoTela"], ""));
          this.data.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], undefined, "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            if (val.naoMostraCampo)
              this.SemOPrimeiroCampo = true;
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val, [], undefined, ""
            );
            ind.valores = this.getValores(element['valores'], ind)
            if (ind.valor.naoMostraCampo) {
              var Vcabecalho = this.getValoresCabecalho(ind.valores)
              if (Vcabecalho != null)
                ind.valor = Vcabecalho;
            }
            this.listaMesa.push(ind);

          });

          this.listaMesa.sort((a, b) => {
            const lengthA = a.valores ? a.valores.length : 0;
            const lengthB = b.valores ? b.valores.length : 0;
            return lengthB - lengthA;
          });
          this.setAtualizacaoindicadores();
          //console.log(this.listaMesa)
        }
      });
    });
  }

  ngOnDestroy() {
    this.intervalIds.forEach(intervalId => clearInterval(intervalId));
  }

  setAtualizacaoindicadores() {
    let login = localStorage.getItem('login');
    const intervalId = setInterval(() => {
      this.atualizando = true;

      this.indicadorEspecialService.getindicadores(this.IdTela, this.CcPosto, this.CcOrgao, this.variaveis, login == undefined ? "" : login).pipe(
      ).subscribe((response) => {
        this.listaMesa = [];
        
        console.log(response)
        this.data = response['indicadores'];
        if (this.data.length == 0) {
          this.SemDados = true;
        }
        else {
          this.data.forEach(element => {
            var v = element['valor'];
            var ind: indicadorDTO = new indicadorDTO(undefined, "", "", 0, "", "", "", null, new valor(undefined, "", "", "", 0, 0, "", 0, null, null, null, null, null, null, null, null, null, null, null, 0, null, [], null), [], undefined, "");
            var val = new valor(
              v['codigo'],
              v['nome'],
              v['expressao'],
              v['icone'],
              v['denominador'],
              v['nominador'],
              v['valorFormatado'],
              v['valorSemFormato'],
              v['naoMostraCampo'],
              v['naoMostraTitulo'],
              v['orientacaoLoop'],
              v['ehLooping'],
              v['ehComponenteGrafico'],
              v['componenteGraficoHtml'],
              v['componenteGraficoCss'],
              v['componenteGraficoPossicao'],
              v['ehLink'],
              v['linkRedirect'],
              v['linkVariaveis'],
              v['ordem'],
              v['ehCampoCabecalho'],
              this.getValores(v['valores'], ind),
              v['cor']
            )
            if (val.naoMostraCampo)
              this.SemOPrimeiroCampo = true;
            ind = new indicadorDTO(
              element['codigo'],
              element['nome'],
              element['cor'],
              element['etapa'],
              element['icone'],
              element['corFarol'],
              element['ordenacaoOrientacao'],
              element['proximaEtapa'],
              val, [], undefined, ""
            );
            ind.valores = this.getValores(element['valores'], ind)
            if (ind.valor.naoMostraCampo) {
              var Vcabecalho = this.getValoresCabecalho(ind.valores)
              if (Vcabecalho != null)
                ind.valor = Vcabecalho;
            }
            this.listaMesa.push(ind);

          });

          this.listaMesa.sort((a, b) => {
            const lengthA = a.valores ? a.valores.length : 0;
            const lengthB = b.valores ? b.valores.length : 0;
            return lengthB - lengthA;
          });
          this.atualizando = false;
        }
      });
    }, (this.tempoAtualizacao * 1000));
    this.intervalIds.push(intervalId);
  }

  navigateWithState(Link: string | null, variaveis: string | null) {
    this.listabreadcrumb[this.listabreadcrumb.length - 1].url = this.router.url.split('?')[0];
    window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, variaveis, CcPosto: this.CcPosto, CcOrgao: this.CcOrgao }
    });
  }

  navigateWithStateBC(Link: string | null) {
    if (Link != null) {
      const index = this.listabreadcrumb.findIndex(b => b.url === Link);
      console.log("LINK", Link);
      if (index !== -1) {
        this.listabreadcrumb = this.listabreadcrumb.slice(0, index);
      }
      window.localStorage.setItem('breadcrumb', JSON.stringify(this.listabreadcrumb));
    }
    this.router.navigate(['' + Link], {
      queryParams: { tipoTela: this.IdTela, CcPosto: this.CcPosto, CcOrgao: this.CcOrgao, textInd: this.textInd, textPos: this.textPos }
    });
  }
  getValoresCabecalho(valores: valor[]): valor | null {
    var retorno: valor | null = null;
    valores.forEach(element => {
      if (element.ehCampoCabecalho)
        retorno = element;
      else {
        var v = this.getValoresCabecalho(element.valores)
        if (v != null)
          retorno = v
      }
    });
    return retorno;
  }

  getValores(response: [], ind: indicadorDTO): valor[] {
    if (response == undefined) {
      return [];
    }
    var listavalores: valor[] = [];
    response.forEach(element => {
      var val = new valor(
        element['codigo'],
        element['nome'],
        element['expressao'],
        element['icone'],
        element['denominador'],
        element['nominador'],
        element['valorFormatado'],
        element['valorSemFormato'],
        element['naoMostraCampo'],
        element['naoMostraTitulo'],
        element['orientacaoLoop'],
        element['ehLooping'],
        element['ehComponenteGrafico'],
        element['componenteGraficoHtml'],
        element['componenteGraficoCss'],
        element['componenteGraficoPossicao'],
        element['ehLink'],
        element['linkRedirect'],
        element['linkVariaveis'],
        element['ordem'],
        element['ehCampoCabecalho'],
        this.getValores(element['valores'], ind),
        element['cor']
      )
      if (val.naoMostraCampo != true) {
        if (!this.cabecalho.find(x => x.nome == val.nome)) {
          var c: cabecalhoDTO = new cabecalhoDTO(val.nome, val.ordem, val.icone);

          this.cabecalho.push(c);
        }
      }
      this.cabecalho.sort((a, b) => {
        return a.ordem - b.ordem
      });
      if (val.ehComponenteGrafico) {
        if (ind.listaComponentesGraficos == undefined)
          ind.listaComponentesGraficos = [];
        if (ind.listaComponentesGraficos.find(x =>
          x.valorFormatado == val.valorFormatado &&
          val.componenteGraficoCss == x.componenteGraficoCss &&
          val.componenteGraficoHtml == x.componenteGraficoHtml &&
          x.componenteGraficoPossicao == val.componenteGraficoPossicao) == null) // checa se ja não colocou o mesmo componente grafico na lista
          ind.listaComponentesGraficos.push(val);
      }
      listavalores.push(val);
      if (val != undefined && val.ehLooping == true) {
        this.ListaDentroLista = true;
      }
    });
    return listavalores;
  }

  MudarVisualizacaoTela(NovoEstilo: string) {
    this.TipoVisualizacaoTela = NovoEstilo;
    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      localStorage.setItem('VISUPADRAO', NovoEstilo);
    }
  }

  exportTableToExcel(): void {
    // Referência à tabela
    const dados: any[] = this.getDados("EXCEL");

    // Função para formatar a data no formato DD/MM/YYYY
    const formatarDataBR = (data: Date) => {
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
  };
  
  const hoje = new Date();
  const nomeUsuario = this.login || 'Usuário não identificado'; // Obtém o nome do usuário
  const dataGeracao = formatarDataBR(hoje); // Formata a data no padrão BR

  // Adiciona uma linha vazia
  dados.push([]);

  // Adiciona uma linha com o nome do usuário e a data em uma célula mesclada
  dados.push([{ 
      v: `Emitente: ${nomeUsuario} | DATA: ${dataGeracao}`,
      s: { 
          alignment: { horizontal: 'center' }, 
          border: { 
              top: { style: 'thin' },
              bottom: { style: 'thin' },
          }
      }
  }]);

  // Adiciona uma linha vazia
  dados.push([]);

  dados.push([{ 
    v: `FONTE: ${this.nomeTipoTela} `,
    s: { 
        alignment: { horizontal: 'center' }, 
        border: { 
            top: { style: 'thin' },
            bottom: { style: 'thin' },
        }
    }
}]);


    // Converte os dados para uma planilha
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dados);
    // Cria um WorkBook
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');

    // Converte para um arquivo binário
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Salva o arquivo
    const data: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    saveAs(data, this.nomeTipoTela.replaceAll(" ", "_") + "-" + this.PostoNome.replaceAll(" ", "_") + "-" + formattedDate + '.xlsx');
  }

  /*extrairTabelaParaPDF() {
    const dados: any[] = this.getDados("PDF");
    const today = new Date();
    const formattedDate1 = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;


    // Função para formatar a data no formato BR
    const formatarDataBR = (data: Date) => {
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
  };

  // Formata a data no formato BR
  const formattedDateBR  = formatarDataBR(today);

  // Obtém o nome do usuário (exemplo de como pegar o nome, modifique conforme sua lógica)
  const nomeUsuario = this.login || 'Usuário não identificado'; 

    const docDefinition = {
      content: [
        { text: this.nomeTipoTela + " - " + this.PostoNome + " - " + formattedDate1, style: 'header' },
        {
          table: {
            body: [
              // Adiciona as linhas processadas da tabela
              ...dados
            ],
          },
        },
        {
          text: `Gerado por: ${nomeUsuario} | Data: ${formattedDateBR}`,
          style: 'footer',
          alignment: 'center', // Alinha o texto ao centro
          margin: [0, 20, 0, 0], // Adiciona uma margem para afastar o texto da tabela
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          //margin: [0, 0, 0, 10]
        },
        footer: {
          fontSize: 10,
          margin: [0, 10, 0, 0], // Ajusta a margem do footer
          alignment: 'center',    // Alinha o texto no centro
      },
    
      },
      
    };


    const formattedDate2 = today.toISOString().split('T')[0];
    // Gera e salva o PDF
    pdfMake.createPdf(docDefinition).download(this.nomeTipoTela.replaceAll(" ", "_") + "-" + this.PostoNome.replaceAll(" ", "_") + "-" + formattedDate2 + '.pdf');
  }*/

   

    extrairTabelaParaPDF() {
      let dados: any[][] = this.getDados("PDF");
  
      if (!dados || dados.length === 0) {
          console.error("Erro: Nenhum dado disponível para exportação.");
          return;
      }
  
      const today = new Date();
      const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
      const formattedTime = `${today.getHours().toString().padStart(2, '0')}:${today.getMinutes().toString().padStart(2, '0')}`;
      const formattedDateTime = `${formattedDate} - ${formattedTime}`;
  
      // Reduz o tamanho da fonte e ajusta a largura das colunas
      const dadosFormatados = dados.map((linha) => 
          linha.map((coluna) => ({
              text: coluna,
              fontSize: 12,
              margin: [2, 2, 2, 2]
          }))
      );
  
      // Definição do PDF
      const docDefinition: any = {
          pageSize: 'A4',
          pageOrientation: 'landscape',
          pageMargins: [20, 40, 20, 20],
  
          content: [
              {
                  columns: [
                      {
                          image: LogosBase64.logo1,
                          width: 100,
                          alignment: 'left',
                          margin: [0, 0, 10, 0]
                      },
                      {
                        width: "*", // Ocupa o espaço do meio
                        text: "" // Espaço vazio entre os logos
                      },
                      {
                        width: "auto", // Ajusta ao conteúdo dos logos
                        alignment: "right",
                        columnGap: 35, // Espaço fixo entre os logos
                        columns: [
                            {
                                image: LogosBase64.logo2,
                                width: 60,
                               
                            },
                            {
                                image: LogosBase64.logo3,
                                width: 60,
                               
                            },
                            {
                                image: LogosBase64.logo4,
                                width: 60,
                               
                            }
                        ]
                    }
                  ]
              },
              { 
                  text: `Relatório: ${this.nomeTipoTela}`, 
                  style: 'header' 
              },
              {
                
                columns: [                  
                  { 
                    //width: "*", // Usa o espaço disponível
                    text:  this.PostoNome + " - "+formattedDateTime, 
                    style: 'posto',                     
                    margin: [0, 30, 0, -10] // Adiciona um espaçamento superior
                  },                 
                  {
                    
                    columns: [
                      
                      { 
                        width: "auto", // Garante que o login não fique comprimido
                        text: `Emitido por: ${this.login}`,
                        style: 'posto', 
                        fontSize: 8,
                        margin: [0, 30, 0, -10] ,// Adiciona um espaçamento superior
                        noWrap: true // Impede a quebra do texto
                      },                        
                    ]
                }
                 
                ],
               
              },              
              {
                  table: {
                      headerRows: 1,
                      widths: Array(dados[0].length).fill('auto'),
                      body: dadosFormatados
                  },
                  margin: [0, 10, 0, 10]
              },
              {
                text: [
                    { text: "Rua Agueda Gonçalves, 240 - Taboão da Serra - SP, 06760-900 - Tel:(11)28456000 (PABX)\n", fontSize: 8, alignment: 'center' },
                    { text: "Correspondência: Caixa Postal 25901 - CEP: 05513-970 - SÃO PAULO-SP\n", fontSize: 8, alignment: 'center' },
                    { text: "www.prodesp.sp.gov.br - prodesp@prodesp.sp.gov.br", fontSize: 8, alignment: 'center' }
                ],
                margin: [0, 10, 0, 0] // Remove espaçamento entre as linhas
            }
              
          ],
          styles: {
              header: {
                  fontSize: 12,
                  bold: true,
                  alignment: 'center'
              },
              posto: {
                fontSize: 10,
                bold: false,
                alignment: 'left',
                
              },                       
          },
          footer: (currentPage: number, pageCount: number) => {
            return {
                text: `Página ${currentPage} de ${pageCount}`,
                alignment: 'center',
                fontSize: 8,
                pageMargins: [20, 40, 20, 40] // Espaçamento do rodapé
            };
          }     
      };
  
      const formattedDate2 = today.toISOString().split('T')[0];
      pdfMake.createPdf(docDefinition).download(this.nomeTipoTela.replaceAll(" ", "_") + "-" + this.PostoNome.replaceAll(" ", "_") + "-" + formattedDate2 + '.pdf');
  }
  
  

  getDados(tipo: string) {
    const dados: any[] = [];

    const tabelas = document.querySelectorAll('#tableDados tbody tr'); // Seleciona todas as linhas da tabela principal

    tabelas.forEach((linha: Element, index: number) => {  // Ajuste aqui para corresponder à assinatura correta
      const celulas = Array.from(linha.querySelectorAll('td')); // Pega todas as células da linha

      const linhaDados: any[] = [];

      // Itera sobre cada célula
      celulas.forEach((celula: HTMLTableCellElement) => {
        const tabelasInternas = Array.from(celula.querySelectorAll('table')); // Procura por tabelas dentro da célula

        if (tabelasInternas.length > 0) {
          // Se houver tabelas internas, nao pega o conteúdo delas tava duplicando os dados
        } else {
          // Caso contrário, pega o texto da célula normal
          if (dados.length == 0 && celula.innerText.trim() == "") {
            linhaDados.push(celula.innerText.trim());
          }
          if (celula.innerText.trim() != "") {
            if (tipo == "PDF") {
              var fontSize = 10;
              if (this.cabecalho.length < 5)
                fontSize = 15;
              else if (this.cabecalho.length < 11)
                fontSize = 10;
              else if (this.cabecalho.length > 11)
                fontSize = 8;

              linhaDados.push({
                text: celula.innerText.trim(),
                fontSize: fontSize // Ajuste o tamanho conforme necessário
              });
            }
            else
              linhaDados.push(celula.innerText.trim());
          }
        }
      });

      if (linhaDados.length > 1) {
        console.log(linhaDados);
        dados.push(linhaDados); // Adiciona a linha de dados à lista de resultados
      }
    });
    return dados;
  }

  extrairConteudoTabelaInterna(tabela: HTMLTableElement): any[] {
    const conteudo: any[] = [];
    const linhasInternas = tabela.querySelectorAll('tr');

    linhasInternas.forEach((linhaInterna: HTMLTableRowElement) => {
      const celulasInternas = Array.from(linhaInterna.querySelectorAll('td'));
      const celulasInternasSemVazio = celulasInternas.filter(x => x.innerText.trim() != "");
      const linhaDadosInterna: any[] = celulasInternasSemVazio.map((celulaInterna: HTMLTableCellElement) => celulaInterna.innerText.trim());
      conteudo.push(linhaDadosInterna);
    });

    return conteudo;
  }
}
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { ServicoService } from '../../../../service/servico.service';
import { servico } from '../../../../modal/regional/servico';
import { orgao } from '../../../../modal/regional/orgao';
import { OrgaoService } from '../../../../service/orgao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';

@Component({
  selector: 'app-cadastro-listagem-servico',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './cadastro-listagem-servico.component.html',
  styleUrl: './cadastro-listagem-servico.component.scss'
})

export class CadastroListagemServicoComponent implements OnInit {
  data = [];
  public listaSer: servico[] = [];
  public listaOrg: orgao[] = [];
  novoItem: servico = new servico();
  pesquisaDesc: string  = "";
  pesquisaOrg: string  = "";
  pesquisaCodCentralizado: string  = "";
  pesquisaCodigo: string  = "";

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  CampoOrdenacao: string = "descricao";
  OrdemOrdenacao: string = "crescente";

  @ViewChild('mySelect') mySelect: any;
  @ViewChild('mySelectFiltro') mySelectFiltro: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicoService: ServicoService,
    private orgaoService: OrgaoService,
    public dialog: MatDialog,
    private authService: AuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }
  }

  onOptionChange(newValue: string) {
    this.novoItem.orgao = this.listaOrg.find(x => x.id == newValue);
    //console.log('Novo item', this.novoItem, newValue, this.listaOrg);
  }

  onOptionChangeFiltro(newValue: string) {
    this.pesquisaOrg = newValue;
  }

  ngOnInit() {
    this.orgaoService.getOrgao().pipe(
    ).subscribe((response) => {
      this.data = response["Orgao"];
      let i = 0;
      //console.log(response);
      this.data.forEach(element => {
        i++;
        this.listaOrg.push(new orgao(element['id'], element['codigo'], element['descricao'],element['codigoCentralizado']))
      });
      this.listaOrg = this.listaOrg.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });
    });

    this.novoItem.orgao = this.listaOrg[0];

    this.Pesquisa();

  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa();
  }

  MudaOrdencacao(Campo: string)
  {
    if(this.CampoOrdenacao == Campo)
      if(this.OrdemOrdenacao == "crescente")
        this.OrdemOrdenacao = "descrecente";
      else
        this.OrdemOrdenacao = "crescente";
    else
    {
      this.CampoOrdenacao = Campo;
      this.OrdemOrdenacao = "crescente";
    }
    this.Pesquisa();
  }

  Pesquisa() {
    this.listaSer = [];
    //console.log(this.pesquisaCodCentralizado)
    this.servicoService.getSerPaginacao(
        this.pageIndex,
        this.pageSize,
        this.pesquisaCodigo,
        this.pesquisaCodCentralizado,
        this.pesquisaDesc,
        this.pesquisaOrg,
        this.CampoOrdenacao,
        this.OrdemOrdenacao)
        .pipe()
        .subscribe(
            (response) => {
      this.data = response["Servico"];
      this.totalPages = response["totalPages"];
      let i = 0;
      //console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new servico(
            element['id'],
            element['codigo'],
            element['descricao'],
            element['descricaoVagas'],
            element['codigoCentralizado']);

        if (element['orgao'] != undefined)
          p.orgao = new orgao(element['orgao']['id'], element['orgao']['codigo'], element['orgao']['descricao'], element['orgao']['codigoCentralizado'])
        this.listaSer.push(p)

      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  excluirItem(id: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Confirmação', message: 'Tem certeza que deseja excluir?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (id != undefined) {
          this.servicoService.deleteItem(id?.toString()).subscribe(
            (response) => {
              //console.log('Item excluído com sucesso!');
              this.Pesquisa();
            },
            (error) => {
              console.error('Erro ao excluir o item:', error);
            }
          );
        }
      }
    });

  }

  AbrirEditarItem(id: string | null): void {
    if (id != undefined) {
      this.servicoService.editarItem(id?.toString()).subscribe(
        (response) => {
          //console.log(response);
          let p = response["Servico"];

          this.novoItem.id = p['id'];
          this.novoItem.codigo = p['codigo'];
          this.novoItem.codigoCentralizado = p['codigoCentralizado'];
          this.novoItem.descricao = p['descricao'];
          if (p['orgao'] != undefined) {
            this.novoItem.orgao = new orgao(p['orgao']['id'], p['orgao']['codigo'], p['orgao']['descricao'], p['orgao']['codigoCentralizado'],)
            this.mySelect.nativeElement.value = '' + p['orgao']['id'];
          }
        },
        (error) => {
          console.error('Erro ao excluir o item:', error);
        }
      );
    }
  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser'){
        return true;
    }
    return false;
  }

  validarRoleSuper(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }
}

<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Configuração de Tabela
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <div class="form-div p-3">
            <label for="descricao" class="form-label">Adicionar nova Tabela:</label>
            <div style="display: flex;">
                <input type="text" class="form-control" placeholder="Descrição" id="descricao" name="descricao"
                    [(ngModel)]="descricao" required>
                <button id="class-button" (click)="CadastrarTabela()" class="btn btn-success"><i
                        class="fas fa-plus"></i></button>
            </div>
        </div>
        <ul class="list-group">
            <label for="descricao" class="form-label">Tabelas Cadastradas:</label>

            @for (p of lista; track $index) {
            <div style="display: flex; margin-top: 10px;">
                <li class="list-group-item" style=" width: 100%;">{{p.descricao}}</li>
                <button id="class-button" (click)="DeletarTabela(p.id)" class="btn btn-danger"><i
                        class="fas fa-trash-alt"></i></button>
            </div>
            }
        </ul>
        <div class="div-button mb-3 p-3" style="justify-content: right; display: flex;">
            <button id="class-button" class="btn btn-secondary"><a
                    style="text-decoration: auto !important; color:white !important;"
                    [routerLink]="['/auditoria-listagem']">Voltar</a></button>
        </div>
    </main>
</section>
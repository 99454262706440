import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { auditoriaCadastro } from '../modal/auditoriaCadastro';

@Injectable({
  providedIn: 'root',
})
export class AuditoriaCadastroService {
  constructor(private http: HttpClient) { }
  private apiUrl = environment.API_URL;
  private map = "ss.auditoria";
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  get(): Observable<any> {
    return this.http.get(this.apiUrl + this.map + '/find', { withCredentials: true });
  }
  getPaginacao(pageNumber: number, pageSize: number, filtroIdentifyNumerico: string, filtroIdentifyDescritivo: string,
    filtroTabela: string, filtroAcao: string, DataInit: string | undefined, DataFim: string | undefined): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
    console.log(DataInit, DataFim)
    var url = this.apiUrl + this.map + '/findpaginacao?pageNumber=' + pageNumber + '&pageSize=' + pageSize +
      '&filtroIdentifyNumerico=' + filtroIdentifyNumerico + '&filtroIdentifyDescritivo=' + filtroIdentifyDescritivo + '&filtroTabela=' + filtroTabela +
      '&filtroAcao=' + filtroAcao;
    if (DataInit != undefined && DataInit != null && DataInit != ""){
      const milis = new Date(DataInit).getTime();
      url = url + '&DataInit=' + milis;
    }
    if (DataFim != undefined && DataFim != null && DataFim != ""){
      const milis = new Date(DataFim).getTime();
      url = url + '&DataFim=' + milis;
    }
    return this.http.get(url, { withCredentials: true });
  }

  adicionarItem(item: auditoriaCadastro): Observable<auditoriaCadastro> {
    return this.http.post<auditoriaCadastro>(this.apiUrl + this.map + '/cadastro', item, { withCredentials: true });
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }
}


<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Cadastro de Perfil de usuários
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        @if(perfilEditado == true){
            <div class="alert alert-success" role="alert">
                Usuário editado com sucesso !
              </div>
        }@if(perfilEditado == false){
            <div class="alert alert-danger" role="alert">
                Ocorreu algum problema na edicação !
              </div>
        }
        @if(perfilSalvo == true){
            <div class="alert alert-success" role="alert">
                Usuário cadastrado com sucesso !
              </div>
        }@if(perfilSalvo == false){
            <div class="alert alert-danger" role="alert">
                Ocorreu algum problema no cadastro !
              </div>
        }

        <!--Tabela -->
        @if(validarRole() == true ){
            <div style="position: fixed; bottom: 58px; right: 22px;">
                <a [routerLink]="['/create-users']"><button id="class-button" style="border-radius: 50%;width: 45px;height: 45px;"
                    class="btn btn-success"><i class="fas fa-plus"></i></button></a>
            </div>
        }


        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaNome" class="form-label">Nome Usuário:</label>
                                    <input type="text" class="form-control" placeholder="Descrição" id="pesquisaNome" name="pesquisaNome" [(ngModel)]="pesquisaNome" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaCPF" class="form-label">CPF:</label>
                                    <input type="text" mask="000.000.000-00" maxlength="14" [dropSpecialCharacters]="false" class="form-control" placeholder="CPF" id="pesquisaCPF" name="pesquisaCPF" [(ngModel)]="pesquisaCPF">
                                </div>
                            </td>
                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                                </div>
                            </td>
                            <!-- <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button [routerLink]="['/create-users']" type="submit" class="btn btn-success">Cadastrar</button>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
        <section class="main-section-table">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Nome do usuário</th>
                        <th scope="col">CPF</th>
                        <th scope="col">Perfil</th>
                        <th scope="col">Edição</th>
                    </tr>
                </thead>
                <tbody>
                    @if (userPerfilControlLista && userPerfilControlLista.length ) {
                        <tr *ngFor="let users of userPerfilControlLista ">
                            <td>{{ users.nome }}</td>
                            <td>{{ users.cpf }}</td>
                            <td>{{ users.meusDados?.roles?.SALADESITUACAO?.perfil }}</td>
                            <td> <button class="btn btn-primary"
                                [routerLink]="['/editar-users']"
                                [queryParams]="{id: users.id}" >Editar Perfil </button>
                                @if(validarRole() == true){
                                    <a [routerLink]="['/auditoria-listagem']"
                                        [queryParams]="{id: users.cpf, tabela: 'Usuario'}"><button
                                            class="btn btn-secondary" style="margin-left: 10px;"><i
                                                class="fas fa-history"></i></button></a>
                                    }
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
            <br>
            <mat-paginator
            #paginator class="demo-paginator"
            (page)="handlePageEvent($event)"
            [length]="totalPages"
            [pageSize]="pageSize"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [hidePageSize]="true"
            [pageIndex]="pageIndex" aria-label="Select page"> </mat-paginator>
        </section>
    </main>
</section>





<section>
    <section style="height: auto;">
        <nav>
            <ol class="cd-breadcrumb custom-separator">
                <table>
                    <tbody>
                        <tr>
                            <td class="nomeTela">
                                Listagem de Auditoria de Cadastro
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ol>
        </nav>
    </section>
    <main>
        <!-- <div class="cd-breadcrumb custom-separator"  role="alert">
            <i class="fa-solid fa-circle-info"></i> Obs: Temos um tempo de esperar de 1 minuto para confirmação de cadastrou/edição de posto!
        </div> -->
        <section class="pesq-section">
            <form class="form-class" data-toggle="collapse" (ngSubmit)="MudaFiltro()">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaidentificadorNumerico" class="form-label">Identificador Numerico:</label>
                                    <input type="text" class="form-control" placeholder="Identificador Numerico" id="identificadorNumerico"
                                        name="pesquisaidentificadorNumerico" [(ngModel)]="pesquisaidentificadorNumerico" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaIdentificadorDescritivo" class="form-label">Identificador Descritivo:</label>
                                    <input type="text" class="form-control" placeholder="Identificador Descritivo"
                                        id="pesquisaIdentificadorDescritivo" name="pesquisaIdentificadorDescritivo"
                                        [(ngModel)]="pesquisaIdentificadorDescritivo" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaUsuario" class="form-label">Usuário:</label>
                                    <input type="text" class="form-control" placeholder="Usuário"
                                        id="pesquisaUsuario" name="pesquisaUsuario" [(ngModel)]="pesquisaUsuario" required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="Acao" class="form-label">Ação:</label>
                                    <select #mySelectFiltroAcao name="select" aria-placeholder="Ação" class="form-select"
                                        (change)="onOptionChangeFiltroAcao(mySelectFiltroAcao.value)">
                                        <option value=""></option>
                                        <option *ngFor="let objeto of opcoesAcao" value={{objeto}}>{{objeto}}
                                        </option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="regiao" class="form-label">Tabela:</label>
                                    <select #mySelectFiltroTabela name="select" aria-placeholder="Regiao"  [(ngModel)]="pesquisaTabela"
                                        class="form-select"
                                        (change)="onOptionChangeFiltroTabela(mySelectFiltroTabela.value)">
                                        <option value=""></option>
                                        <option *ngFor="let objeto of listaTabela" value="{{objeto.descricao}}" >{{objeto.descricao}}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDataInit" class="form-label">Data de Inicio:</label>
                                    <input type="date" class="form-control" placeholder="Data de Inicio"
                                        id="pesquisaDataInit" name="pesquisaDataInit" [(ngModel)]="pesquisaDataInit"
                                        required>
                                </div>
                            </td>
                            <td>
                                <div class="form-div p-3">
                                    <label for="pesquisaDataFim" class="form-label">Data Fim:</label>
                                    <input type="date" class="form-control" placeholder="Data Fim" id="pesquisaDataFim"
                                        name="pesquisaDataFim" [(ngModel)]="pesquisaDataFim" required>
                                </div>
                            </td>
                            <td>
                                <div class="div-button mb-3 p-3" style="margin-top: 47px;">
                                    <button id="class-button" type="submit" class="btn btn-primary">Pesquisar</button>
                                </div>
                            </td>
                            <td style=" position: absolute; right: 45px;">
                                <a  [routerLink]="['/tabela-cadastro']"><button id="class-button" class="btn btn-success">Configurar Tabelas</button></a>
                              </td>
                        </tr>
                    </tbody>
                </table>
            </form>

        </section>
        <!--Tabela -->
        <section class="main-section-table">
            <!-- <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator> -->
            <table class="table">
                <!--toJsonBody-->
                <thead>

                    <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" style="cursor: pointer">Identificador Numerico</th>
                        <th scope="col" style="cursor: pointer">Identificador Descritivo</th>
                        <th scope="col" style="cursor: pointer">Tabela</th>
                        <th scope="col" style="cursor: pointer">Ação</th>
                        <th scope="col" style="cursor: pointer">Usuario</th>
                        <th scope="col" style="cursor: pointer">Data alteração</th>
                        <!--<th scope="col">Serviços do posto</th>
            <th scope="col">Órgãos do posto</th>-->
                        @if(validarRoleEditar() == true){
                        <th scope="col">Funções</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (p of lista; track $index) {
                    <tr>
                        <!-- <td style="font-weight: bold;">{{$index+1}}</td> -->
                        <td style="font-weight: bold;">{{p.identificadorNumerico}}</td>
                        <td>{{p.identificadorDescritivo}}</td>
                        <td>{{p.tabela}}</td>
                        <td>{{p.acao}}</td>
                        <td>{{p.usuario}}</td>
                        <td>{{dateTimeFormatPipe.transform(p.dataAtualizacao)}}</td>


                        <!--<td></td>
            <td></td>-->
                        @if(validarRoleEditar() == true)
                        {
                        <td>
                            <a  [routerLink]="['/auditoria-visualizar']" [queryParams]="{IdEditar: p.id, id: pesquisaidentificadorNumerico, tabela: pesquisaTabela }"><button class="btn btn-primary" style="margin-right: 10px;">Visualizar</button></a>
                        </td>
                        }


                    </tr>
                    }
                </tbody>
            </table>

            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalPages"
                [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
                [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" [pageIndex]="pageIndex"
                aria-label="Select page">
            </mat-paginator>

        </section>


    </main>
</section>


export class auditoriaCadastro {
    public id: string | null;
    public identificadorNumerico: string;
    public identificadorDescritivo: string;
    public tabela:string; 
    public acao:string; 
    public dataAtualizacao: number | undefined; 
    public usuario: string;
    public jsonOriginal: string;
    public jsonAlterado: string;

    constructor(
        id: string | null = null,
        identificadorNumerico: string = "",
        identificadorDescritivo: string = "",
        tabela: string = "",
        dataAtualizacao: number | undefined = undefined,
        acao : string = "",
        usuario: string = "",
        jsonOriginal: string = "",
        jsonAlterado: string = ""
    ) {
        this.id = id;
        this.identificadorNumerico = identificadorNumerico;
        this.identificadorDescritivo = identificadorDescritivo;
        this.tabela = tabela;
        this.acao = acao
        this.dataAtualizacao= dataAtualizacao;
        this.usuario= usuario;
        this.jsonOriginal= jsonOriginal;
        this.jsonAlterado= jsonAlterado;
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { servico } from '../modal/regional/servico';

@Injectable({
  providedIn: 'root',
})
export class ServicoService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.servico";
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  getServico(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
  }
  getSerPaginacao(pageNumber: number, pageSize: number, filtroCod: string, filtroCodCentral: string,filtroDesc: string, filtroOrg: string, CampoOrdenacao : string,OrdemOrdenacao: string): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroCod='+filtroCod+'&filtroCodCentral='+filtroCodCentral+'&filtroDesc='+filtroDesc+'&filtroOrg='+filtroOrg+'&campoOrdenacao='+CampoOrdenacao+'&ordemOrdenacao='+OrdemOrdenacao, {withCredentials: true});
  }

  adicionarItem(item: servico): Observable<servico> {
    let login = localStorage.getItem('login');
    return this.http.post<servico>(this.apiUrl+this.map+'/cadastro?nomeUsuario='+login, item, {withCredentials: true});
  }

  deleteItem(id: string): Observable<any> {
    let login = localStorage.getItem('login');
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar?nomeUsuario=`+login+`&id=${id}`);
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }
}

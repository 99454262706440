import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { boxInfo } from '../../../../modal/boxInfo';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { PostoService } from '../../../../service/posto.service';
import { posto } from '../../../../modal/regional/posto';
import { regiao } from '../../../../modal/regional/regiao';
import { RegiaoService } from '../../../../service/regiao.service';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialog/confirmdialog.component';
import { AuthService } from '../../../../service/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { auditoriaCadastro } from '../../../../modal/auditoriaCadastro';
import { AuditoriaCadastroService } from '../../../../service/auditoriaCadastro.service';
import { DateTimeFormatPipe } from '../../../../utilidades/date-time-format.pipe';
import { tabela } from '../../../../modal/tabela';
import { TabelaService } from '../../../../service/tabela.service';

@Component({
  selector: 'app-auditoria-cadastro-listagem',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatCardModule,
    MatMenuModule,
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterModule,
    FormsModule,
    MatPaginatorModule
  ],
  templateUrl: './auditoria-cadastro-listagem.component.html',
  styleUrl: './auditoria-cadastro-listagem.component.scss',
  providers: [DateTimeFormatPipe]
})

export class AuditoriaCadastroListagemComponent implements OnInit {
  data = [];
  public lista: auditoriaCadastro[] = [];
  public listaTabela: tabela[] = [];
  pesquisaTabela: string  = "";
  pesquisaAcao: string  = "";
  pesquisaUsuario: string  = "";
  pesquisaidentificadorNumerico: string =""
  pesquisaIdentificadorDescritivo: string= "";
  pesquisaDataInit: string | undefined = undefined;
  pesquisaDataFim: string | undefined = undefined;
  id: string | undefined = undefined;
  tabela: string | undefined = undefined;

  opcoesAcao = ['Cadastro', 'Excluir', 'Editar', 'Ativar', 'Inativar'];

  opcoesTabela = ['Opção 1', 'Opção 2', 'Opção 3'];

  pageIndex = 0;
  pageSize = 10;
  totalPages = 1;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  @ViewChild('mySelectFiltroAcao') mySelectFiltroAcao: any;
  @ViewChild('mySelectFiltroTabela') mySelectFiltroTabela: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private auditoriaCadastroService: AuditoriaCadastroService,
    public dialog: MatDialog,
    private tabelaService: TabelaService,
    private authService: AuthService,
    private oauthService: OAuthService) {
    try {
      let login = localStorage.getItem('login');
      if (login == null || login == undefined)
        this.router.navigate(['/iniciar']);
    }
    catch (ex) {
      this.router.navigate(['/iniciar']);
    }

  }

  onOptionChangeFiltroTabela(newValue: string) {
    this.pesquisaTabela = newValue;
  }

  onOptionChangeFiltroAcao(newValue: string) {
    this.pesquisaAcao = newValue;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.tabela = params['tabela'];
      if (this.id != undefined){
        this.pesquisaidentificadorNumerico = this.id;
      }
      if (this.tabela != undefined){
        this.pesquisaTabela = this.tabela;
        this.mySelectFiltroTabela.nativeElement.value = ""+this.tabela;
      }
    });
    this.tabelaService.get().pipe(
      ).subscribe((response) => {
        this.listaTabela = []
        this.data = response["Tabela"];
        let i = 0;
        console.log(response);
        this.data.forEach(element => {
          i++;
          let p = new tabela(element['id'],
           element['descricao']);
          this.listaTabela.push(p)
  
        });
      });

    this.Pesquisa();
  }

  MudaFiltro()
  {
    this.pageIndex =0;
    this.Pesquisa()
  }

  Pesquisa() {
    this.lista = [];
    this.auditoriaCadastroService.getPaginacao(this.pageIndex, this.pageSize, this.pesquisaidentificadorNumerico,
       this.pesquisaIdentificadorDescritivo,this.pesquisaTabela, this.pesquisaAcao, this.pesquisaDataInit, this.pesquisaDataFim).pipe(
    ).subscribe((response) => {
      this.lista = []
      this.data = response["AUDITORIA"];
      this.totalPages = response["totalPages"];
      let i = 0;
      console.log(response);
      this.data.forEach(element => {
        i++;
        let p = new auditoriaCadastro(element['id'],
         element['identificadorNumerico'], element['identificadorDescritivo'],
         element['tabela'],element['dataAtualizacao'],
         element['acao'],element['usuario'],
         element['jsonOriginal'],element['jsonAlterado']);
        this.lista.push(p)

      });
    });
  }

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.totalPages = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.Pesquisa();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }


  validarRoleEditar(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }

  validarRole(){
    let role = this.authService.role();
    if(role == 'superuser' || role == 'superintendencia'){
        return true;
    }
    return false;
  }

}

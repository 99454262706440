import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { orgao } from '../modal/regional/orgao';

@Injectable({
  providedIn: 'root',
})
export class OrgaoService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.API_URL;
  private map = "ss.orgao";

  getOrgao(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
  }

  getOrgPaginacao(pageNumber: number, pageSize: number, filtroCod: string, filtroCodCentral: string,filtroDesc: string): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/findpaginacao?pageNumber='+pageNumber+'&pageSize='+pageSize+'&filtroCod='+filtroCod+'&filtroCodCentral='+filtroCodCentral+'&filtroDesc='+filtroDesc, {withCredentials: true});
  }

  adicionarItem(item: orgao): Observable<orgao> {
    let login = localStorage.getItem('login');
    return this.http.post<orgao>(this.apiUrl+this.map+'/cadastro?nomeUsuario='+login, item, {withCredentials: true});
  }

  deleteItem(id: string): Observable<any> {
    let login = localStorage.getItem('login');
    return this.http.delete<any>(`${this.apiUrl}${this.map}/deletar?nomeUsuario=`+login+`&id=${id}`);
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.map}/findbyId/${id}`);
  }

  getOrgaoAgenda(): Observable<any> {
    return this.http.get(this.apiUrl+this.map+'/findOrgaoAgenda', {withCredentials: true});
  }
}

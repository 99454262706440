import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestOptions } from '../modal/RequestOptions';
import { environment } from '../../environments/environments';
import { indicador } from '../modal/Indicador';

@Injectable({
  providedIn: 'root',
})
export class IndicadorService {
  constructor(private http: HttpClient) { }
  private apiUrl = environment.API_URL;
  private map = this.apiUrl + "ss.indicadores";
  findIndicadores(postos: number[] | undefined, login: string, ccPostoFiltro: string): Observable<any> {
    //const myParams = new HttpParams().set('password',"admin").set('username', "admin");
    if(ccPostoFiltro == null || ccPostoFiltro == undefined)
      ccPostoFiltro = "";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    return this.http.post(this.map + '/findIndicadores?login=' + login+ '&CcPostoFiltro=' + ccPostoFiltro, { params: postos }, { withCredentials: true });
  }
  AtualizaIndicador(postos: number[] | undefined, CodigoIndicador: string, login: string, ccPostoFiltro: string): Observable<any> {
    if(ccPostoFiltro == null || ccPostoFiltro == undefined)
      ccPostoFiltro = "";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    return this.http.post(this.map + '/atualizaIndicador?login=' + login + '&CodigoIndicador=' + CodigoIndicador+ '&CcPostoFiltro=' + ccPostoFiltro, { params: postos }, { withCredentials: true });
  }

  GetFarol(postos: number[] | undefined, CodigoIndicador: string, login: string, ccPostoFiltro: string): Observable<any> {
    if(ccPostoFiltro == null || ccPostoFiltro == undefined)
      ccPostoFiltro = "";
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    return this.http.post(this.map + '/getFarol?login=' + login + '&CodigoIndicador=' + CodigoIndicador+ '&CcPostoFiltro=' + ccPostoFiltro, { params: postos }, { withCredentials: true });
  }

  findGraficoTelaInteira(TipoTela: string, Etapa: number, TipoGrafico: number, CodigoIndicador: number, variaveis: string | undefined, login: string, postos: number[] | undefined, ccPostoFiltro: string = ""): Observable<any> {
    //const myParams = new HttpParams().set('password',"admin").set('username', "admin");
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    console.log(variaveis, CodigoIndicador);
    if (variaveis == undefined)
      return this.http.post(this.map + '/findGraficoTelaInteira?tipoTela=' + TipoTela +
        '&etapa=' + Etapa + '&login=' + login +
        '&tipoGrafico=' + TipoGrafico +
        '&codigoIndicador=' + CodigoIndicador +
        '&CcPostoFiltro=' + ccPostoFiltro +
        '&variaveis=', { params: postos }, { withCredentials: true });
    else
      return this.http.post(this.map + '/findGraficoTelaInteira?tipoTela=' + TipoTela +
        '&etapa=' + Etapa + '&login=' + login +
        '&tipoGrafico=' + TipoGrafico +
        '&codigoIndicador=' + CodigoIndicador +
        '&CcPostoFiltro=' + ccPostoFiltro +
        '&variaveis=' + encodeURIComponent(JSON.stringify(variaveis)), { params: postos }, { withCredentials: true });
  }

  findGraficoAdicional(TipoTela: string, Etapa: number, TipoGrafico: number, CodigoIndicador: number, variaveis: string | undefined, login: string): Observable<any> {
    //const myParams = new HttpParams().set('password',"admin").set('username', "admin");
    const headers = new HttpHeaders({ 'Content-Type': 'application/json;' });
    console.log(variaveis, CodigoIndicador, TipoGrafico);
    if (variaveis == undefined)
      return this.http.post(this.map + '/findGraficoAdicional?tipoTela=' + TipoTela +
        '&etapa=' + Etapa + '&login=' + login +
        '&tipoGrafico=' + TipoGrafico +
        '&codigoIndicador=' + CodigoIndicador +
        '&variaveis=', { withCredentials: true });
    else
      return this.http.post(this.map + '/findGraficoAdicional?tipoTela=' + TipoTela +
        '&etapa=' + Etapa + '&login=' + login +
        '&tipoGrafico=' + TipoGrafico +
        '&codigoIndicador=' + CodigoIndicador +
        '&variaveis=' + encodeURIComponent(JSON.stringify(variaveis)), { withCredentials: true });
  }


  obterIndicadorPorCodigo(codigo: number): Observable<any> {
    const url = this.map + '/IndicadorByCodigo/' + codigo;
    return this.http.get(url);
  }

  getIndicador(): Observable<any> {
    return this.http.get(this.map + '/findAll', { withCredentials: true });
  }

  getIndicadorAtapa1SemGrafico(): Observable<any> {
    return this.http.get(this.map + '/findAllEtapa1SemGrafico', { withCredentials: true });
  }

  getIndPaginacao(pageNumber: number, pageSize: number, filtroDesc: string, filtroCodigo: number | undefined, filtroEtapa: string, pesquisaGrafico: number | undefined,): Observable<any> {
    //return this.http.get(this.apiUrl+this.map+'/find', {withCredentials: true});
    var variaveis = "";
    if (filtroCodigo != undefined)
      variaveis = '&filtroCodigo=' + filtroCodigo;
    else
      variaveis = '&filtroCodigo=';
    if (pesquisaGrafico != undefined)
      variaveis = variaveis + '&filtroGrafico=' + pesquisaGrafico;
    else
      variaveis = variaveis + '&filtroGrafico=';

    return this.http.get(this.map + '/findpaginacao?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&filtroDesc=' + filtroDesc + '&filtroEtapa=' + filtroEtapa+ variaveis, { withCredentials: true });

  }

  adicionarItem(item: indicador): Observable<indicador> {
    let login = localStorage.getItem('login');
    return this.http.post<indicador>(this.map + '/cadastro?nomeUsuario='+login, item, { withCredentials: true });
  }

  alterarPosições(item: indicador[]): Observable<any> {
    let login = localStorage.getItem('login');
    return this.http.post<any>(this.map + '/savePosicoes?nomeUsuario='+login, item, { withCredentials: true });
  }

  InativarItem(id: string): Observable<any> {
    let login = localStorage.getItem('login');
    return this.http.get<any>(this.map+'/inativar?id='+id+'&nomeUsuario='+login);
  }

  AtivarItem(id: string): Observable<any> {
    let login = localStorage.getItem('login');
    return this.http.get<any>(this.map+'/ativar?id='+id+'&nomeUsuario='+login);
  }

  editarItem(id: string): Observable<any> {
    return this.http.get<any>(`${this.map}/findbyId/${id}`);
  }

}

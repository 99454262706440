import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { PAsContratados } from '../../../modal/PAsCadastrados';
import { PASCadastradosService } from '../../../service/pascadastrados.service';
import { PostoService } from '../../../service/posto.service';
@Component({
    selector: 'app-pas-cadastrados',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,
        CommonModule,
        RouterLink,
        RouterOutlet,
        RouterModule,
        FormsModule,
        MatPaginatorModule,
    ],
    templateUrl: './pas-cadastrados.component.html',
    styleUrl: './pas-cadastrados.component.scss',
})
export class PasCadastradosComponent implements OnInit {

    public data = [];
    public body: PAsContratados[] = [];
    novoItem: PAsContratados = new PAsContratados();
    public MensagemError = "";
    public MensagemAlert = "";
    // Paginação
    pageEvent: PageEvent | undefined;
    totalPages: any;
    pageSize: any;
    pageIndex: any;
    disabled = false;
    showFirstLastButtons = true;
    hidePageSize = false;
    showPageSizeOptions = true;
    public listaPostos: any = [];
    //Pesquisa
    pesquisaIdPonto: string = '';
    @ViewChild('mySelect') mySelect: any;

    constructor(private pASContratService: PASCadastradosService, public dialog: MatDialog,
        private routeActivated: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef, private zone: NgZone,
        public postoService: PostoService) {
        // Login
        try {
            let login = localStorage.getItem('login');
            if (login == null || login == undefined)
                this.router.navigate(['/iniciar']);
        }
        catch (ex) {
            this.router.navigate(['/iniciar']);
        }
    }

    ngOnInit() {
        this.routeActivated.queryParams.subscribe((params) => {
            let id = params["id"]

            var usuario = localStorage.getItem('salaSituacao');
            this.postoService.getPostoUsuario(usuario).pipe().subscribe((json: any) => {
                json.Posto.forEach((postos: any) => {
                    if (postos.regiao) {
                        var posto: any = { descricao: postos.descricao.toUpperCase(), codigoCentralizado: postos.codigoCentralizado, idRegiao: postos.regiao.id, regiao: postos.regiao.codigo }
                        this.listaPostos.push(posto);
                    }
                });
                this.listaPostos.sort(function (a: any, b: any) {
                    if (a.descricao > b.descricao) {
                        return 1;
                    }
                    if (a.descricao < b.descricao) {
                        return -1;
                    }
                    return 0;
                })
                if (id !== null && id !== undefined) {
                    this.editarPASContratadoItemPorIDTelaCadastro(id)
                }
            })
        })
    }

    onOptionChange(newValue: string) {
        this.novoItem.id = null;
        this.MensagemAlert = "";
        this.novoItem.quantidadePontos =0;
        var postoEscolhido = this.listaPostos.find((x: { codigoCentralizado: string; }) => x.codigoCentralizado == newValue);
        this.novoItem.idPosto = postoEscolhido.codigoCentralizado;
        this.novoItem.nomePosto = postoEscolhido.descricao;
        this.editarPASContratadoItemPorPostoID(this.novoItem.idPosto+"");
        console.log('Novo item', this.novoItem, newValue);
    }

    //editar por id
    editarPASContratadoItemPorIDTelaCadastro(id: string): void {

        this.pASContratService.editarPASContratadoItemPorIDTelaCadastro(id?.toString()).subscribe(
            (response) => {
                console.log(response)
                let p = response["pontos"];
                console.log(p)
                this.novoItem.id = p["id"];
                this.novoItem.idPosto = p["idPosto"];
                this.mySelect.nativeElement.value = p["idPosto"];
                this.novoItem.nomePosto = p["nomePosto"];
                this.novoItem.quantidadePontos = p["quantidadePontos"];
                this.novoItem.dataCriacao = p["dataCriacao"];
            },
            (error) => {
                console.error("Erro ao atualizar item", error);
            }
        )
    }

    editarPASContratadoItemPorPostoID(id: string): void {

        this.pASContratService.GetPASContratadoPorPostoID(id?.toString()).subscribe(
            (response) => {                
                console.log(response)
                let p = response["pontos"];
                if(p != undefined){
                console.log(p)
                this.MensagemAlert = "Já exite um cadastro para o Posto "+ p["nomePosto"] +", seu modo de Tela foi alterado para Editar."
                this.novoItem.id = p["id"];
                this.novoItem.idPosto = p["idPosto"];
                this.mySelect.nativeElement.value = p["idPosto"];
                this.novoItem.nomePosto = p["nomePosto"];
                this.novoItem.quantidadePontos = p["quantidadePontos"];
                this.novoItem.dataCriacao = p["dataCriacao"];
                }
            },
            (error) => {
                console.error("Erro ao atualizar item", error);
            }
        )
    }


    Limpar() {
        this.novoItem.dataCriacao = '';
        this.novoItem.id = '';
        this.novoItem.idPosto = undefined;
        this.novoItem.nomePosto = "";
        this.mySelect.nativeElement.value = '';
    }

    voltar() {
        this.router.navigate(['pas-listagem'])
    }

    submitForm() {
        if (this.novoItem.nomePosto == undefined || this.novoItem.nomePosto == "" || this.novoItem.idPosto == undefined || this.novoItem.quantidadePontos == undefined) {
            this.MensagemError = ("Digite todos os campos obrigatorios!");
            return;
        }
        else if (this.novoItem.quantidadePontos.toString().length > 3) {
            this.MensagemError = ("A quantidade de Pontos não pode ter mais que 3 dígitos.");
            return;
        }
        else {
            console.log('Formulário submetido:', this.novoItem);
            this.pASContratService
                .PostAdicionarItem(this.novoItem)
                .subscribe(
                    () => {
                        //console.log('Novo item adicionado com sucesso');
                        this.novoItem = new PAsContratados();
                        this.router.navigate(['/pas-listagem']);
                    },
                    (error) => {
                        console.error('Erro ao adicionar o novo item: ', error);
                    }
                );
        }

    }


    pageSizeOptions: number[] = [5, 10, 25];
    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput
                .split(',')
                .map((str) => +str);
        }
    }



}


export class tabela {
    public id: string | null;
    public descricao: string;
    constructor(
        id: string | null = null,
        descricao: string = ""
    ) {
        this.id = id;
        this.descricao = descricao;
    }
}
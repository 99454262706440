import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilSharedDataService {
  private modoApresentacaoSource = new Subject<boolean>();
  private indicadorAprensentacaoSource = new Subject<string>();

  modoApresentacao$ = this.modoApresentacaoSource.asObservable();
  indicadorAprensentacao$ = this.indicadorAprensentacaoSource.asObservable();

  updateModoApresentacao(modoApresentacao: boolean) {
    this.modoApresentacaoSource.next(modoApresentacao);
  }

  updateIndicadorAprensentacao(indicadorAprensentacao: string) {
    this.indicadorAprensentacaoSource.next(indicadorAprensentacao);
  }
}